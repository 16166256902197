import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import config from '@/config/app';
import { message, notification } from "ant-design-vue";
import { getStorage } from "./utils";

interface http_result {
    data: any,
    msg: string
}

import FingerprintJS from '@fingerprintjs/fingerprintjs'
const fpPromise = FingerprintJS.load()
let devId="";

class Http {

    constructor() {
        this.axios = axios.create({
            baseURL: config.api.base_url,
            timeout: 30000,
        });

        //请求拦截器
        this.axios.interceptors.request.use(async (config) => {
            const fp = await fpPromise
            const result = await fp.get()

            //请求头带上唯一码
            if(!devId){
                devId=result.visitorId;
            }
            config.headers["Dev-Id"]=devId;

            //请求头带上token
            const token = getStorage('stoken') || '';
            config.headers.Authorization = 'Bearer ' + token

            console.log("请求参数", config)

            if (!config.data) {
                config.data = {};
            }


            return config
        }, (error) => {
            notification.error({
                message: '错误',
                description:
                    '请求错误',
            });
            // 对请求错误做些什么
            return Promise.reject(error);
        })

        this.axios.interceptors.response.use((response) => {
            console.log(response.data)
            if (response.status !== 200) {
                notification.error({
                    message: '错误',
                    description:
                        '网络错误，请检查网络连接',
                });
            }
            return response;
        }, (error) => {
            if (error.response) {
                console.log(error.response)
                const { data, status } = error.response;
                switch (status) {
                    case 401: {//鉴权失败，跳转到登录界面
                        location.href = '/login';
                        break;
                    }
                    default: {
                        let msg = data
                        if (data && data.msg) {
                            msg = data.msg
                        }
                        message.error(msg);
                        break;
                    }

                }
            } else {
                console.log(error)
                notification.error({
                    message: '错误',
                    description:
                        '网络错误，请检查网络连接',
                });
            }

            // 对响应错误做点什么
            return Promise.reject(error);
        });

    }

    public axios: AxiosInstance;

    public static _instance: Http;

    public static getInstance(): Http {
        Http._instance || (Http._instance = new Http())
        return Http._instance;
    }

    //get请求
    public async get(url: string, params?: any, config?: AxiosRequestConfig): Promise<http_result> {
        const response = await this.axios.get(url, { params, ...config });
        return response.data;
    }

    //post请求
    public async post(url: string, data?: any, config?: AxiosRequestConfig): Promise<http_result> {
        const response = await this.axios.post(url, data, config);
        return response.data;
    }

    //put请求
    public async put(url: string, data?: any, config?: AxiosRequestConfig): Promise<http_result> {
        const response = await this.axios.put(url, data, config);
        return response.data;
    }

    //patch请求
    public async patch(url: string, data?: any, config?: AxiosRequestConfig): Promise<http_result> {
        const response = await this.axios.patch(url, data, config);
        return response.data;
    }

    //delete请求
    public async delete(url: string, config?: AxiosRequestConfig): Promise<http_result> {
        const response = await this.axios.delete(url, config);
        return response.data;
    }
}

export default Http.getInstance()