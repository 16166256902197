<template>
  <div>
    <a-card class="l-table-search" :bodyStyle="{ padding: '20px 20px 0' }" v-if="showSearch">
      <slot name="search"></slot>
    </a-card>
    <a-card :bodyStyle="{ padding: '5px 10px 10px 10px' }">
      <div class="l-table-toolbar">
        <slot name="toolbar"></slot>
      </div>
      <slot></slot>
    </a-card>
  </div>
</template>

<script lang="ts">
import { defineComponent, useSlots } from "vue";

export default defineComponent({
  name: "lTableContainer",
  setup() {
    const showSearch = !!useSlots().search;

    return {
      showSearch,
    };
  },
});
</script>
