<template>
  <a-layout style="min-height: 100vh">
    <a-layout-header class="l-main-header">
      <div class="logo">乐棋游戏管理后台</div>
      <div class="l-flex l-flex-center l-flex-1" style="justify-content: flex-end">
        <div style="margin-left: 10px; color: #fff">
          <a-dropdown>
            <div @click.prevent style="cursor: pointer">
              <a-avatar :size="30">
                <template #icon>
                  <UserOutlined />
                </template>
              </a-avatar>

              <span style="margin-left: 10px">
                {{ userinfo.name }}
                <DownOutlined />
              </span>
            </div>
            <template #overlay>
              <a-menu>
                <a-menu-item>
                  <div @click="changePwdVisible = true">
                    <LockOutlined class="l-font-primary" />
                    <span style="margin-left: 7px">修改密码</span>
                  </div>
                </a-menu-item>
                <a-menu-item>
                  <div @click="logout">
                    <LogoutOutlined class="l-font-danger" />
                    <span style="margin-left: 7px">退出登录</span>
                  </div>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
      </div>
    </a-layout-header>
    <a-layout style="height: 0">
      <a-layout-sider v-model:collapsed="collapsed" collapsible :style="{ overflow: 'auto', position: 'relative', left: 0, background: '#fff' }" v-show="mediaType == 'lg'">
        <a-menu v-model:selectedKeys="selectedKeys" v-model:openKeys="openKeys" mode="inline" @click="handleMenuClick">
          <a-sub-menu v-for="item in menuList" :key="item.key">
            <template #title>
              <span>
                <component :is="item.icon"></component>

                <span>{{ item.title }}</span>
              </span>
            </template>
            <a-menu-item v-for="child in item.children" :key="child.key">{{ child.title }}</a-menu-item>
          </a-sub-menu>
        </a-menu>
      </a-layout-sider>

      <!--当屏幕太小时，菜单按钮变成图标，点击后使用抽屉展开菜单-->
      <div class="l-menu-fixed-container" :class="{ show: showDrawerMenu }" @click="showDrawerMenu = !showDrawerMenu" v-show="mediaType != 'lg'">
        <MenuOutlined v-if="!showDrawerMenu" />
        <CloseOutlined v-else />
      </div>

      <a-layout style="overflow-y: auto">
        <div style="background: #fff; margin-bottom: 10px" :tab-bar-style="{ margin: 0 }">
          <a-tabs class="l-main-tabs" type="editable-card" v-model:activeKey="menuActiveKey" hide-add @change="handleMenuTabsChange" @edit="handleMenuTabsEdit">
            <a-tab-pane :tab="item.title" :closable="menuTabsList.length != 1" :key="item.key" v-for="item in menuTabsList"></a-tab-pane>
          </a-tabs>
        </div>

        <a-layout-content class="l-main" :class="{ 'l-main-mg': currMenuName }">
          <!-- <a-breadcrumb style="margin: 16px 0" v-if="currMenuName">
            <a-breadcrumb-item href="/console/home" @click="navToHome">
              <HomeOutlined />
              <span style="margin-left: 7x">首页</span>
            </a-breadcrumb-item>
            <a-breadcrumb-item v-if="currMenuName">{{ currMenuName }}</a-breadcrumb-item>
          </a-breadcrumb>-->
          <div style="flex: 1">
            <router-view></router-view>
          </div>
        </a-layout-content>
      </a-layout>
    </a-layout>

    <l-drawer title="修改密码" :width="500" v-model:visible="changePwdVisible" :body-style="{ paddingBottom: '80px' }">
      <ChangePwd @close="changePwdVisible = false" />
    </l-drawer>

    <a-drawer class="l-memu-draw" placement="left" title :width="250" :closable="false" v-model:visible="showDrawerMenu" :body-style="{ paddingBottom: '0', paddingLeft: 0, paddingRight: 0, paddingTop: 0 }">
      <a-menu v-model:selectedKeys="selectedKeys" v-model:openKeys="openKeys" mode="inline" @click="handleMenuClick">
        <a-sub-menu v-for="item in menuList" :key="item.key">
          <template #title>
            <span>
              <component :is="item.icon"></component>

              <span>{{ item.title }}</span>
            </span>
          </template>
          <a-menu-item v-for="child in item.children" :key="child.key">{{ child.title }}</a-menu-item>
        </a-sub-menu>
      </a-menu>
    </a-drawer>
  </a-layout>
</template>
<script lang="ts">
import http from "@/common/http";
import { getStorage, showConfirm } from "@/common/utils";
import { UserOutlined, MenuOutlined, LogoutOutlined, HomeOutlined, FileProtectOutlined, SolutionOutlined, NotificationOutlined, UsergroupAddOutlined, TransactionOutlined, PieChartOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import { defineComponent, inject, onMounted, provide, reactive, ref, UnwrapRef } from "vue";
import { useRouter } from "vue-router";
import ChangePwd from "../user/ChangePwd.vue";
import io from "socket.io-client";
import config from "@/config/app";
import router from "@/router";

interface userinfo {
  name: string;
  username: string;
}

export default defineComponent({
  components: {
    UserOutlined,
    LogoutOutlined,
    ChangePwd,
    HomeOutlined,
    FileProtectOutlined,
    SolutionOutlined,
    NotificationOutlined,
    UsergroupAddOutlined,
    TransactionOutlined,
    PieChartOutlined,
    MenuOutlined,
  },
  setup() {
    //响应式标识
    const mediaType = inject("mediaType");
    const showDrawerMenu = ref(false);

    const router = useRouter();
    //菜单数据
    const selectedKeys = ref<string[]>([]);
    const menuList = ref<any>([]);
    const openKeys = ref<string[]>([]);
    const sk = ref<any>("");
    const menuActiveKey = ref("0");
    const menuTabsList = ref<any>([]);

    //是否显示修改密码
    const changePwdVisible = ref(false);

    //当前的菜单
    const currMenuName = ref("");

    //当前登录的用户信息
    let userinfo: UnwrapRef<userinfo> = reactive({
      name: "",
      username: "",
    });

    //首页数据
    const homeData = ref({
      currOnlineCount: 0,
      currPlayCount: 0,
    });
    provide("homeData", homeData);
    //首页在线人数数据
    const homeGameCountChartData = ref([]);
    provide("homeGameCountChartData", homeGameCountChartData);

    //点击菜单
    const handleMenuClick = ({ key }: { key: string }) => {
      let menu_item: any = {};
      selectedKeys.value = [key];
      menuList.value.forEach((item: any) => {
        if (item.key == key) {
          menu_item = item;
          if (item.route == "/console/home") {
            currMenuName.value = "";
          } else {
            currMenuName.value = item.title;
          }
        } else {
          item.children.forEach((child: any) => {
            if (child.key == key) {
              menu_item = child;
              if (child.route == "/console/home") {
                currMenuName.value = "";
              } else {
                currMenuName.value = child.title;
              }
            }
          });
        }
      });

      //增加tab
      addPane(menu_item);
      router.push(menu_item.route);
    };

    //增加顶部的菜单tab
    const addPane = (menu_item: any) => {
      const index = menuTabsList.value.findIndex((item: any) => {
        return item.key == menu_item.key;
      });

      menuActiveKey.value = menu_item.key;

      if (index == -1) {
        menuTabsList.value.push(menu_item);
      }
    };

    //点击顶部tab菜单
    const handleMenuTabsChange = (activeKey: any) => {
      handleMenuClick({ key: activeKey });
    };

    //删除tab
    const handleMenuTabsEdit = (targetKey: any, action: any) => {
      if (action == "remove") {
        //如果当前是活跃的key，则优先切换到前一个key，如果没有前一个key，则切换到后一个key
        const index = menuTabsList.value.findIndex((item: any) => {
          return item.key == targetKey;
        });
        if (index != -1) {
          if (targetKey == menuActiveKey.value) {
            if (index > 0) {
              handleMenuClick({ key: menuTabsList.value[index - 1].key });
            } else {
              handleMenuClick({ key: menuTabsList.value[index + 1].key });
            }
          }

          menuTabsList.value.splice(index, 1);
        }
      }
    };

    //退出登录
    const logout = () => {
      showConfirm({
        title: "退出登录",
        content: "确定要退出登录吗?",
        onOk: async () => {
          await http.delete(`session`);
          message.success("退出成功");
          location.href = "/login";
        },
      });
    };

    //跳转到首页
    const navToHome = () => {
      currMenuName.value = "";
      router.push("/console/home");
    };

    //获取当前的用户信息
    const getUserInfo = async () => {
      const { data } = await http.get("session");
      userinfo.name = data.userinfo.name;
      userinfo.username = data.userinfo.username;
    };

    //初始化socketio
    const initSocketIo = () => {
      //初始化socketio
      sk.value = io("ws://" + config.socketio_url, {
        transports: ["websocket"],
      });

      sk.value.on("connect", async () => {
        //连接成功获取二维码内容,发送设备ID到后台
        const token = getStorage("stoken") || "";
        sk.value.emit("login", token);
      });

      //接收服务端扫码成功后发送过来的token
      sk.value.on("loginSuccess", () => {
        console.log("登录成功");
      });

      //接收后台推送来的游戏人数和在线人数
      sk.value.on("gameCount", (msg: any) => {
        const data: any = JSON.parse(msg);
        homeData.value.currPlayCount = data.currPlayCount;
        homeData.value.currOnlineCount = data.currOnlineCount;
      });

      //接收后台推送来的游戏人数和在线人数的图表数据
      sk.value.on("gameCountChart", (msg: any) => {
        const data: any = JSON.parse(msg);
        homeGameCountChartData.value = data;
      });

      sk.value.on("disconnect", () => {
        console.log("dis", sk.value.id); // undefined
      });
    };

    //获取用户信息
    onMounted(() => {
      getUserInfo();
    });

    return {
      initSocketIo,
      handleMenuClick,
      collapsed: ref<boolean>(false),
      selectedKeys,
      menuList,
      openKeys,
      changePwdVisible,
      currMenuName,
      menuActiveKey,
      menuTabsList,
      handleMenuTabsEdit,
      logout,
      navToHome,
      userinfo,
      mediaType,
      showDrawerMenu,
      handleMenuTabsChange,
    };
  },

  methods: {},

  beforeRouteEnter({ fullPath }, from, next) {
    next(async (vm: any) => {
      const { data } = await http.get("auth/menu");
      vm.menuList = data.menu_tree;

      vm.$nextTick(() => {
        //如果进入的是console 自动跳转到第一个菜单
        if (fullPath == "/console" || fullPath == "/console/") {
          const item = vm.menuList[0].children[0];

          vm.currMenuName = item.title;

          vm.selectedKeys = [item.key];
          vm.menuTabsList.push(item);
          vm.menuActiveKey = item.key;

          router.push(item.route);
          return;
        }

        vm.menuList.forEach((item: any) => {
          if (item.route == fullPath) {
            if (item.route == "/console/home") {
              vm.currMenuName = "";
            } else {
              vm.currMenuName = item.title;
            }

            vm.selectedKeys = [item.key];
            vm.menuTabsList.push(item);
            vm.menuActiveKey = item.key;
          } else {
            item.children.forEach((child: any) => {
              if (child.route == fullPath) {
                vm.selectedKeys = [child.key];
                vm.openKeys = [item.key];

                if (child.route == "/console/home") {
                  vm.currMenuName = "";
                } else {
                  vm.currMenuName = child.title;
                }

                vm.menuTabsList.push(child);
                vm.menuActiveKey = child.key;
              }
            });
          }
        });
      });
    });
  },
});
</script>
<style lang="scss" scope>
.logo {
  height: 32px;
  width: 176px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
  color: #fff;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

// .site-layout .site-layout-background {
//   background: #fff;
// }
// [data-theme="dark"] .site-layout .site-layout-background {
//   background: #141414;
// }

.l-main-header {
  // background: #fff !important;
  padding: 0 !important;
  height: 50px !important;
  line-height: 50px !important;
  display: flex;
  align-items: center;
  padding-right: 15px !important;
  // justify-content: flex-end;
}

.l-main {
  display: flex;
  flex-direction: column;
}

.l-main-mg {
  margin: 0 16px;
}

.l-menu-fixed-container {
  position: fixed;
  top: 50px;
  left: 0;
  background: #fff;
  padding: 10px 13px;
  height: auto;
  font-size: 20px;
  z-index: 9999999;
  box-shadow: 3px 1px 3px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: left 0.25s;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  &.show {
    left: 248px;
  }
}

.l-memu-draw {
  .ant-drawer-content-wrapper {
    width: 250px !important;
  }
}

.l-main-tabs {
  .ant-tabs-nav {
    margin: 0 !important;
    // margin-left: 20px !important;
  }
}
</style>
