export const AUTH = {
    NAMESPACE: 'auth',
    //向后台请求当前登录用户的权限
    REQUEST_AUTH: 'requestAuth'
}

//多语言
export const LANG = {
    NAMESPACE: 'lang',
    //向后台请求语言列表
    REQUEST_LANG: 'requestLang'
}