
import { Row } from "ant-design-vue/lib/grid";
import { computed, defineComponent, h, ref } from "vue";
import Action from "./search_container_action.vue";

export default defineComponent({
  name: "l-search-container",
  setup(props, { slots, emit }) {
    const showAllSearch = ref(false);

    const defaultSlot = computed(() => {
      if (!showAllSearch.value) {
        return slots.default!().filter((item, index) => {
          return index < 3;
        });
      } else {
        return slots.default!();
      }
    });

    return () =>
      h(
        Row,
        {
          gutter: 20,
          type: "flex",
        },
        {
          default: () => [
            defaultSlot.value,
            h(Action, {
              onSearch: () => {
                emit("search");
              },
              onReset: () => {
                emit("reset");
              },
              "onUpdate:visible": (visible: any) => (showAllSearch.value = visible),
              visible: showAllSearch.value,
              slotsCount: slots.default!().length,
            }),
          ],
        }
      );
  },
});
