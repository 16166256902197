
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    images: {
      type: Array,
      required: true,
    },
    height: {
      type: String,
      default: "30px",
    },
    width: {
      type: String,
      default: "30px",
    },
  },
  setup() {
    const show = () => {};

    return {
      show,
    };
  },
});
