
import { defineComponent } from "vue";

export default defineComponent({
  name: "l-search-container-action",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    slotsCount: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    //点击搜索按钮
    const onSearch = () => {
      emit("search");
    };

    //点击重置按钮
    const onReset = () => {
      emit("reset");
    };

    //点击展开/关闭
    const handleToggle = () => {
      emit("update:visible", !props.visible);
    };

    return {
      onSearch,
      onReset,
      handleToggle,
    };
  },
});
