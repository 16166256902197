<template>
  <Auth :auth="auth">
    <template v-slot:default>
      <a-button v-bind="$attrs">
        <slot></slot>
      </a-button>
    </template>

    <template v-slot:denied>
      <a-button v-bind="$attrs" disabled>
        <slot></slot>
      </a-button>
    </template>
  </Auth>
</template>

<script>
import Auth from "./Auth";
import { defineComponent } from "vue";

export default defineComponent({
  name: "l-auth-button",
  inheritAttrs: false,
  components: {
    Auth,
  },
  props: {
    auth: {
      type: String,
      required: true,
    },
  },
});
</script>
