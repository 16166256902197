
import { NamePath } from "ant-design-vue/lib/form/interface";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "l-form",
  setup() {
    const formRef = ref();

    const validate = (nameList?: NamePath[]): Promise<void> => {
      return formRef.value.validate(nameList);
    };

    const validateFields = (nameList?: NamePath[]): Promise<void> => {
      return formRef.value.validateFields(nameList);
    };

    const scrollToField = (name: NamePath, options: [any]) => {
      formRef.value.scrollToField(name, options);
    };

    const resetFields = () => {
      formRef.value.resetFields();
    };

    const clearValidate = (name: Array<any> | string) => {
      formRef.value.clearValidate(name);
    };

    return {
      formRef,
      validate,
      validateFields,
      scrollToField,
      resetFields,
      clearValidate,
    };
  },
});
