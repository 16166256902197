
import http from "@/common/http";
import { getStorage, showConfirm } from "@/common/utils";
import { UserOutlined, MenuOutlined, LogoutOutlined, HomeOutlined, FileProtectOutlined, SolutionOutlined, NotificationOutlined, UsergroupAddOutlined, TransactionOutlined, PieChartOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import { defineComponent, inject, onMounted, provide, reactive, ref, UnwrapRef } from "vue";
import { useRouter } from "vue-router";
import ChangePwd from "../user/ChangePwd.vue";
import io from "socket.io-client";
import config from "@/config/app";
import router from "@/router";

interface userinfo {
  name: string;
  username: string;
}

export default defineComponent({
  components: {
    UserOutlined,
    LogoutOutlined,
    ChangePwd,
    HomeOutlined,
    FileProtectOutlined,
    SolutionOutlined,
    NotificationOutlined,
    UsergroupAddOutlined,
    TransactionOutlined,
    PieChartOutlined,
    MenuOutlined,
  },
  setup() {
    //响应式标识
    const mediaType = inject("mediaType");
    const showDrawerMenu = ref(false);

    const router = useRouter();
    //菜单数据
    const selectedKeys = ref<string[]>([]);
    const menuList = ref<any>([]);
    const openKeys = ref<string[]>([]);
    const sk = ref<any>("");
    const menuActiveKey = ref("0");
    const menuTabsList = ref<any>([]);

    //是否显示修改密码
    const changePwdVisible = ref(false);

    //当前的菜单
    const currMenuName = ref("");

    //当前登录的用户信息
    let userinfo: UnwrapRef<userinfo> = reactive({
      name: "",
      username: "",
    });

    //首页数据
    const homeData = ref({
      currOnlineCount: 0,
      currPlayCount: 0,
    });
    provide("homeData", homeData);
    //首页在线人数数据
    const homeGameCountChartData = ref([]);
    provide("homeGameCountChartData", homeGameCountChartData);

    //点击菜单
    const handleMenuClick = ({ key }: { key: string }) => {
      let menu_item: any = {};
      selectedKeys.value = [key];
      menuList.value.forEach((item: any) => {
        if (item.key == key) {
          menu_item = item;
          if (item.route == "/console/home") {
            currMenuName.value = "";
          } else {
            currMenuName.value = item.title;
          }
        } else {
          item.children.forEach((child: any) => {
            if (child.key == key) {
              menu_item = child;
              if (child.route == "/console/home") {
                currMenuName.value = "";
              } else {
                currMenuName.value = child.title;
              }
            }
          });
        }
      });

      //增加tab
      addPane(menu_item);
      router.push(menu_item.route);
    };

    //增加顶部的菜单tab
    const addPane = (menu_item: any) => {
      const index = menuTabsList.value.findIndex((item: any) => {
        return item.key == menu_item.key;
      });

      menuActiveKey.value = menu_item.key;

      if (index == -1) {
        menuTabsList.value.push(menu_item);
      }
    };

    //点击顶部tab菜单
    const handleMenuTabsChange = (activeKey: any) => {
      handleMenuClick({ key: activeKey });
    };

    //删除tab
    const handleMenuTabsEdit = (targetKey: any, action: any) => {
      if (action == "remove") {
        //如果当前是活跃的key，则优先切换到前一个key，如果没有前一个key，则切换到后一个key
        const index = menuTabsList.value.findIndex((item: any) => {
          return item.key == targetKey;
        });
        if (index != -1) {
          if (targetKey == menuActiveKey.value) {
            if (index > 0) {
              handleMenuClick({ key: menuTabsList.value[index - 1].key });
            } else {
              handleMenuClick({ key: menuTabsList.value[index + 1].key });
            }
          }

          menuTabsList.value.splice(index, 1);
        }
      }
    };

    //退出登录
    const logout = () => {
      showConfirm({
        title: "退出登录",
        content: "确定要退出登录吗?",
        onOk: async () => {
          await http.delete(`session`);
          message.success("退出成功");
          location.href = "/login";
        },
      });
    };

    //跳转到首页
    const navToHome = () => {
      currMenuName.value = "";
      router.push("/console/home");
    };

    //获取当前的用户信息
    const getUserInfo = async () => {
      const { data } = await http.get("session");
      userinfo.name = data.userinfo.name;
      userinfo.username = data.userinfo.username;
    };

    //初始化socketio
    const initSocketIo = () => {
      //初始化socketio
      sk.value = io("ws://" + config.socketio_url, {
        transports: ["websocket"],
      });

      sk.value.on("connect", async () => {
        //连接成功获取二维码内容,发送设备ID到后台
        const token = getStorage("stoken") || "";
        sk.value.emit("login", token);
      });

      //接收服务端扫码成功后发送过来的token
      sk.value.on("loginSuccess", () => {
        console.log("登录成功");
      });

      //接收后台推送来的游戏人数和在线人数
      sk.value.on("gameCount", (msg: any) => {
        const data: any = JSON.parse(msg);
        homeData.value.currPlayCount = data.currPlayCount;
        homeData.value.currOnlineCount = data.currOnlineCount;
      });

      //接收后台推送来的游戏人数和在线人数的图表数据
      sk.value.on("gameCountChart", (msg: any) => {
        const data: any = JSON.parse(msg);
        homeGameCountChartData.value = data;
      });

      sk.value.on("disconnect", () => {
        console.log("dis", sk.value.id); // undefined
      });
    };

    //获取用户信息
    onMounted(() => {
      getUserInfo();
    });

    return {
      initSocketIo,
      handleMenuClick,
      collapsed: ref<boolean>(false),
      selectedKeys,
      menuList,
      openKeys,
      changePwdVisible,
      currMenuName,
      menuActiveKey,
      menuTabsList,
      handleMenuTabsEdit,
      logout,
      navToHome,
      userinfo,
      mediaType,
      showDrawerMenu,
      handleMenuTabsChange,
    };
  },

  methods: {},

  beforeRouteEnter({ fullPath }, from, next) {
    next(async (vm: any) => {
      const { data } = await http.get("auth/menu");
      vm.menuList = data.menu_tree;

      vm.$nextTick(() => {
        //如果进入的是console 自动跳转到第一个菜单
        if (fullPath == "/console" || fullPath == "/console/") {
          const item = vm.menuList[0].children[0];

          vm.currMenuName = item.title;

          vm.selectedKeys = [item.key];
          vm.menuTabsList.push(item);
          vm.menuActiveKey = item.key;

          router.push(item.route);
          return;
        }

        vm.menuList.forEach((item: any) => {
          if (item.route == fullPath) {
            if (item.route == "/console/home") {
              vm.currMenuName = "";
            } else {
              vm.currMenuName = item.title;
            }

            vm.selectedKeys = [item.key];
            vm.menuTabsList.push(item);
            vm.menuActiveKey = item.key;
          } else {
            item.children.forEach((child: any) => {
              if (child.route == fullPath) {
                vm.selectedKeys = [child.key];
                vm.openKeys = [item.key];

                if (child.route == "/console/home") {
                  vm.currMenuName = "";
                } else {
                  vm.currMenuName = child.title;
                }

                vm.menuTabsList.push(child);
                vm.menuActiveKey = child.key;
              }
            });
          }
        });
      });
    });
  },
});
