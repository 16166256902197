<template>
  <div class="l-login-container">
    <div class="l-login-wrapper">
      <div class="l-login-title">乐棋游戏业务员后台</div>

      <a-form ref="form_ref" :model="form_data" :rules="rules">
        <a-form-item label="" name="username">
          <a-input v-model:value="form_data.username" placeholder="请输入用户名" size="large">
            <template #prefix>
              <user-outlined type="user" style="color: rgba(0, 0, 0, 0.5)" />
            </template>
          </a-input>
        </a-form-item>
        <a-form-item label="" name="password">
          <a-input-password v-model:value="form_data.password" placeholder="请输入密码" size="large">
            <template #prefix>
              <lock-outlined type="lock" style="color: rgba(0, 0, 0, 0.5)" />
            </template>
          </a-input-password>
        </a-form-item>

        <!-- <div style="margin-bottom: 15px; cursor: pointer">
          <img @click="getCaptcha" :src="captcha.b64s" style="width: 100%" />
        </div> -->

        <a-form-item label="" name="verify_code">
          <a-row :gutter="16">
            <a-col :span="16">
              <a-input placeholder="验证码" v-model:value="form_data.verify_code" size="large">
                <template #prefix> <safety-outlined style="color: rgba(0, 0, 0, 0.5)" /> </template>
              </a-input>
            </a-col>
            <a-col :span="8"> <img @click="getCaptcha" :src="captcha.b64s" style="width: 100%; height: 38px; cursor: pointer" /></a-col>
          </a-row>
        </a-form-item>

        <a-form-item style="margin-top: 50px">
          <a-button type="primary" size="large" @click="onSubmit" block :loading="loading">登录</a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script lang="ts">
import http from "@/common/http";
import { setStorage } from "@/common/utils";
import { message } from "ant-design-vue";
import { defineComponent, onMounted, reactive, ref } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {},
  setup() {
    const form_ref = ref();
    const router = useRouter();
    const activeKey = ref("1");
    const qrstr = ref("");
    const sk = ref<any>("");
    const qrexp = ref(0);

    const form_data = reactive({
      username: "",
      password: "",
      verify_code: "",
      verify_id: "",
      login_type: 1,
    });

    const captcha = reactive({
      id: "",
      b64s: "",
    });

    const rules = {
      username: {
        required: true,
        message: "请输入用户名",
      },
      password: {
        required: true,
        message: "请输入密码",
      },

      verify_code: {
        required: true,
        message: "请输入验证码",
      },
    };

    //获取验证码
    const getCaptcha = async () => {
      const { data } = await http.get("/captcha", form_data);
      captcha.id = data.id;
      captcha.b64s = data.b64s;
      form_data.verify_id = data.id;
    };

    //提交
    let loading = ref(false);
    const onSubmit = () => {
      form_ref.value
        .validate()
        .then(async () => {
          loading.value = true;

          try {
            const ret = await http.post("/session", form_data);
            setStorage("stoken", ret.data.token);
            loading.value = false;
            message.success("登录成功");
            router.push("/console");
          } catch (e) {
            getCaptcha();
            loading.value = false;
          }
        })
        .catch((error: any) => {
          console.log("error", error);
        });
    };

    //重置
    const resetForm = () => {
      form_ref.value.resetFields();
    };

    //初始化socketio
    // const initSocketIo = () => {
    //   //初始化socketio
    //   sk.value = io("ws://" + config.socketio_url, {
    //     transports: ["websocket"],
    //   });

    //   sk.value.on("connect", async () => {
    //     if (!devId) {
    //       const fp = await fpPromise;
    //       const result = await fp.get();

    //       //请求头带上唯一码
    //       devId = result.visitorId;
    //     }

    //     //连接成功获取二维码内容,发送设备ID到后台
    //     sk.value.emit("loginInit", devId);

    //     // // or with emit() and custom event names
    //     // sk.value.emit("salutations", "Hello!", { mr: "john" }, Uint8Array.from([1, 2, 3, 4]));
    //   });

    //   //接收服务端发送过来的二维码字符串
    //   sk.value.on("loginQrstr", (msg: any) => {
    //     qrstr.value = msg;

    //     //过期时间倒计时
    //     clearTimeout(timeoutFp.value);
    //     qrexp.value = 300;
    //     qrTimeDown();
    //   });

    //   //接收服务端扫码成功后发送过来的token
    //   sk.value.on("loginToken", (msg: any) => {
    //     setStorage("stoken", msg);
    //     message.success("登录成功");
    //     router.push("/console/home");
    //   });

    //   sk.value.on("disconnect", () => {
    //     console.log("dis", sk.value.id); // undefined
    //   });
    // };

    //刷新二维码
    const refreshQrCode = () => {
      sk.value.emit("loginInit");
    };

    // //二维码过期倒计时
    // const qrTimeDown = () => {
    //   if (qrexp.value > 0) {
    //     timeoutFp.value = setTimeout(() => {
    //       qrexp.value--;
    //       qrTimeDown();
    //     }, 1000);
    //   }
    // };

    onMounted(() => {
      getCaptcha();
      // initSocketIo();
    });

    return {
      form_ref,
      form_data,
      rules,
      loading,
      captcha,
      activeKey,
      qrstr,

      getCaptcha,
      onSubmit,
      resetForm,
      refreshQrCode,
      qrexp,
    };
  },
});
</script>

<style lang="scss" scoped>
.l-login-title {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 70px;
}
.l-login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: url("~@/assets/images/login-bg.svg");
  background-size: 100% 100%;

  .l-login-wrapper {
    height: auto;
    width: 350px;
  }
}
.l-qr-exp-model {
  background: rgba(255, 255, 255, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}
</style>
