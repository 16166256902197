import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';

import VueViewer from 'v-viewer';
import 'viewerjs/dist/viewer.css'

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import STable from '@surely-vue/table';

//引入自定义scss文件
import '@/assets/scss/main.scss'
import '@/assets/scss/media.scss'

//引入自定义antd样式文件
import '@/assets/scss/theme.scss'

import LtableContainer from '@/components/table_container/table_container.vue'
import LTable from '@/components/table/table.vue'
import LNum from '@/components/num/num.vue'
import Lmodal from '@/components/modal/modal.vue'
import Lform from '@/components/form/form.vue'
import LDrawer from '@/components/drawer/drawer.vue'
import LTextButton from '@/components/text_button/text_button.vue'
import LSearchContainer from '@/components/search_container/search_container.vue'
import LuploadDragger from '@/components/upload/UploadDragger.vue'
import LImgViewer from '@/components/img_viewer/img_viewer.vue'


//引入antd图标
import {
    PlusOutlined, FormOutlined, EditOutlined, DeleteOutlined, EditFilled, DeleteFilled, SettingOutlined, ColumnHeightOutlined, ReloadOutlined, DownOutlined,
    UpOutlined, LockOutlined, AppstoreOutlined, ToolOutlined, ProfileOutlined, DownloadOutlined, UserOutlined, SafetyOutlined, CloseOutlined, LinkOutlined,
    InboxOutlined, MinusOutlined, ExclamationCircleOutlined, InfoCircleOutlined, UnlockOutlined, RetweetOutlined, PercentageOutlined, CheckCircleOutlined, CloseCircleOutlined,
    RollbackOutlined, RiseOutlined, ArrowUpOutlined, ArrowDownOutlined, SketchOutlined
} from "@ant-design/icons-vue";

//引入权限组件
import {
    Auth,
    AuthButton,
    AuthSwitch,
    AuthIcon,
    AuthTextButton
} from "@/components/auth/index"

const app = createApp(App);
app.use(Antd);
app.use(STable);

//图片预览组件
app.use(VueViewer)
app.component('l-img-viewer', LImgViewer);

app.component('l-table-container', LtableContainer);
app.component('l-table', LTable);
app.component('l-modal', Lmodal);
app.component('l-form', Lform);
app.component('l-drawer', LDrawer);
app.component('l-text-button', LTextButton);
app.component('l-search-container', LSearchContainer);
app.component('l-upload-dragger', LuploadDragger);
app.component('l-num', LNum);

app.component(Auth.name, Auth);
app.component(AuthButton.name, AuthButton);
app.component(AuthSwitch.name, AuthSwitch);
app.component(AuthIcon.name, AuthIcon);
app.component(AuthTextButton.name, AuthTextButton);

//引入图标
app.component('PlusOutlined', PlusOutlined);
app.component('FormOutlined', FormOutlined);
app.component('EditOutlined', EditOutlined);
app.component('DeleteOutlined', DeleteOutlined);
app.component('EditFilled', EditFilled);
app.component('DeleteFilled', DeleteFilled);
app.component('SettingOutlined', SettingOutlined);
app.component('ColumnHeightOutlined', ColumnHeightOutlined);
app.component('ReloadOutlined', ReloadOutlined);
app.component('DownOutlined', DownOutlined);
app.component('UpOutlined', UpOutlined);
app.component('LockOutlined', LockOutlined);
app.component('AppstoreOutlined', AppstoreOutlined);
app.component('ToolOutlined', ToolOutlined);
app.component('ProfileOutlined', ProfileOutlined);
app.component('DownloadOutlined', DownloadOutlined);
app.component('UserOutlined', UserOutlined);
app.component('SafetyOutlined', SafetyOutlined);
app.component('CloseOutlined', CloseOutlined);
app.component('LinkOutlined', LinkOutlined);
app.component('inbox-outlined', InboxOutlined);
app.component('MinusOutlined', MinusOutlined);
app.component('ExclamationCircleOutlined', ExclamationCircleOutlined);
app.component('InfoCircleOutlined', InfoCircleOutlined);
app.component('UnlockOutlined', UnlockOutlined);
app.component('RetweetOutlined', RetweetOutlined);
app.component('PercentageOutlined', PercentageOutlined);
app.component('CheckCircleOutlined', CheckCircleOutlined);
app.component('CloseCircleOutlined', CloseCircleOutlined);
app.component('RollbackOutlined', RollbackOutlined);
app.component('RiseOutlined', RiseOutlined);
app.component('ArrowUpOutlined', ArrowUpOutlined);
app.component('ArrowDownOutlined', ArrowDownOutlined);
app.component('SketchOutlined', SketchOutlined);


//引入json查看组件
import JsonViewer from "vue3-json-viewer"
app.use(JsonViewer)

//设置日期为中文
dayjs.locale('zh');


app.use(store).use(router).mount('#app')


