<template>
  <a-upload-dragger :beforeUpload="beforeUpload" @change="handleChange" :accept="accept" :headers="headers" :action="fullAction" v-bind="$attrs">
    <slot></slot>
  </a-upload-dragger>
</template>

<script lang="ts">
import { getStorage } from "@/common/utils";
import config from "@/config/app";
import { message } from "ant-design-vue";
import { defineComponent, onMounted, ref } from "vue";

import FingerprintJS from "@fingerprintjs/fingerprintjs";
const fpPromise = FingerprintJS.load();
let devId = "";

interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: string;
  url?: string;
  type?: string;
  size: number;
  originFileObj: any;
}

interface FileInfo {
  file: FileItem;
  fileList: FileItem[];
}

export default defineComponent({
  name: "l-upload-dragger",

  props: {
    accept: {
      type: String,
      default: ".jpg,.png,.bmp",
    },

    action: {
      type: String,
      required: true,
    },

    limitSize: {
      type: Number,
    },

    beforeupload: {
      type: Function,
      default: null,
    },

    limitCount: {
      type: Number,
    },
  },
  setup(props, { emit }) {
    //上传前的钩子
    const beforeUpload = (file: FileItem) => {
      return new Promise((resolve, reject) => {
        //检查文件大小限制
        if (props.limitSize) {
          if (props.limitSize * 1024 * 1024 < file.size) {
            message.error("上传文件大小不能超过" + props.limitSize + "M");

            return reject(false);
          }
        }

        //检查后缀名
        let index = file.name!.lastIndexOf(".");
        let ext = file.name!.substr(index);
        if (!props.accept.split(",").includes(ext.toLowerCase())) {
          message.error("只允许上传以下类型的文件:" + props.accept);
          return reject(false);
        }

        if (props.beforeupload !== null) {
          return props.beforeupload(file);
        } else {
          return resolve(true);
        }
      });
    };

    const fullAction = config.api.base_url + props.action;

    //上传改变
    const handleChange = (info: FileInfo) => {
      const status = info.file.status;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} 上传成功`);
        emit("success", info.file.response, info.file);
      } else if (status === "error") {
        message.error(`${info.file.name} 上传失败.`);
      }
    };

    //上传的请求头
    const token = getStorage("stoken") || "";
    const headers = ref<any>({
      Authorization: "Bearer " + token,
    });

    onMounted(async () => {
      //请求头带上唯一码
      if (!devId) {
        const fp = await fpPromise;
        const result = await fp.get();
        devId = result.visitorId;
      }

      headers.value["Dev-Id"] = devId;
    });

    return {
      fullAction,
      headers,
      beforeUpload,
      handleChange,
    };
  },
});
</script>
