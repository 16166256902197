
import { defineComponent } from "vue";

export default defineComponent({
  name: "lDrawer",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const handleClose = () => {
      // emit("update:visible", false);
    };

    return {
      handleClose,
    };
  },
});
