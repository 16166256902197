<template>
  <Auth :auth="auth">
    <template v-slot:default>
      <i v-bind="$attrs">
        <slot></slot>
      </i>
    </template>
  </Auth>
</template>

<script>
import Auth from "./Auth";
import { defineComponent } from "vue";

export default defineComponent({
  name: "l-auth-icon",
  inheritAttrs: false,
  components: {
    Auth,
  },
  props: {
    auth: {
      type: String,
      required: true,
    },
  },
});
</script>
