
import { defineComponent } from "vue";

export default defineComponent({
  name: "l-text-button",
  props: {
    type: {
      type: String,
      default: "primary",
    },
  },
  setup(props) {
    const classArray = ["l-text-button", props.type];

    return {
      classArray,
    };
  },
});
