
import Index from '../views/index/Index.vue'
import Login from '../views/login/Login.vue'
import Forbidden from '../views/error/Forbidden.vue'

//需要登录
const requireLoginMeta = { requireLogin: true };

//需要访问权限
const requireAuthMeta = { requireAuth: true };

const routes = [
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/',
        redirect: '/console/home'
    },

    {
        path: '/console',
        name: 'console',
        component: Index,
        // meta: { ...requireLoginMeta, ...requireAuthMeta },
        children: [
            {
                path: 'home',
                name: 'home',
                meta: { ...requireLoginMeta, ...requireAuthMeta },
                component: () => import(/* webpackChunkName: "home" */ '@/views/index/Home.vue')
            },

            

            {
                path: 'ch_gold_log',
                name: 'ch_gold_log',
                meta: { ...requireLoginMeta, ...requireAuthMeta },
                component: () => import(/* webpackChunkName: "ch_gold_log" */ '@/views/ch_gold_log/Index.vue')
            },


            {
                path: 'us_user',
                name: 'us_user',
                meta: { ...requireLoginMeta, ...requireAuthMeta },
                component: () => import(/* webpackChunkName: "us_user" */ '@/views/us_user/Index.vue')
            },
            
            
            {
                path: 'us_bearns_use_log',
                name: 'us_bearns_use_log',
                meta: { ...requireLoginMeta, ...requireAuthMeta },
                component: () => import(/* webpackChunkName: "us_bearns_use_log" */ '@/views/us_bearns_use_log/Index.vue')
            },

            
            {
                path: 'us_group_info_logs',
                name: 'us_group_info_logs',
                meta: { ...requireLoginMeta, ...requireAuthMeta },
                component: () => import(/* webpackChunkName: "us_group_info_logs" */ '@/views/us_group_info_logs/Index.vue')
            },

            
            {
                path: 'us_club',
                name: 'us_club',
                meta: { ...requireLoginMeta, ...requireAuthMeta },
                component: () => import(/* webpackChunkName: "us_club" */ '@/views/us_club/Index.vue')
            },

            {
                path: 'us_club_white_list',
                name: 'us_club_white_list',
                meta: { ...requireLoginMeta, ...requireAuthMeta },
                component: () => import(/* webpackChunkName: "us_club_white_list" */ '@/views/us_club_white_list/Index.vue')
            },


            {
                path: 'us_club_join_apply',
                name: 'us_club_join_apply',
                meta: { ...requireLoginMeta, ...requireAuthMeta },
                component: () => import(/* webpackChunkName: "us_club_join_apply" */ '@/views/us_club_join_apply/Index.vue')
            },

            {
                path: 'us_club_inviter',
                name: 'us_club_inviter',
                meta: { ...requireLoginMeta, ...requireAuthMeta },
                component: () => import(/* webpackChunkName: "us_club_inviter" */ '@/views/us_club_inviter/Index.vue')
            },

            {
                path: 'us_goldcoin_change_log',
                name: 'us_goldcoin_change_log',
                meta: { ...requireLoginMeta, ...requireAuthMeta },
                component: () => import(/* webpackChunkName: "us_goldcoin_change_log" */ '@/views/us_goldcoin_change_log/Index.vue')
            },

            {
                path: 'us_club_blacklist_room',
                name: 'us_club_blacklist_room',
                meta: { ...requireLoginMeta, ...requireAuthMeta },
                component: () => import(/* webpackChunkName: "us_club_blacklist_room" */ '@/views/us_club_blacklist_room/Index.vue')
            },

            {
                path: 'us_club_opertion_log',
                name: 'us_club_opertion_log',
                meta: { ...requireLoginMeta, ...requireAuthMeta },
                component: () => import(/* webpackChunkName: "us_club_opertion_log" */ '@/views/us_club_opertion_log/Index.vue')
            },

            {
                path: 'gm_club_opr_log',
                name: 'gm_club_opr_log',
                meta: { ...requireLoginMeta, ...requireAuthMeta },
                component: () => import(/* webpackChunkName: "gm_club_opr_log" */ '@/views/gm_club_opr_log/Index.vue')
            },

            {
                path: 'us_club_member_hp_log',
                name: 'us_club_member_hp_log',
                meta: { ...requireLoginMeta, ...requireAuthMeta },
                component: () => import(/* webpackChunkName: "us_club_member_hp_log" */ '@/views/us_club_member_hp_log/Index.vue')
            },
        
            {
                path: 'gm_club_income_statistics',
                name: 'gm_club_income_statistics',
                meta: { ...requireLoginMeta, ...requireAuthMeta },
                component: () => import(/* webpackChunkName: "gm_club_income_statistics" */ '@/views/gm_club_income_statistics/Index.vue')
            },

            {
                path: 'us_club_standings',
                name: 'us_club_standings',
                meta: { ...requireLoginMeta, ...requireAuthMeta },
                component: () => import(/* webpackChunkName: "us_club_standings" */ '@/views/us_club_standings/Index.vue')
            },

            
            {
                path: 'gm_day_club_user_group_statistics',
                name: 'gm_day_club_user_group_statistics',
                meta: { ...requireLoginMeta, ...requireAuthMeta },
                component: () => import(/* webpackChunkName: "gm_day_club_user_group_statistics" */ '@/views/gm_day_club_user_group_statistics/Index.vue')
            },

            {
                path: 'us_club_member_safe_log',
                name: 'us_club_member_safe_log',
                meta: { ...requireLoginMeta, ...requireAuthMeta },
                component: () => import(/* webpackChunkName: "us_club_member_safe_log" */ '@/views/us_club_member_safe_log/Index.vue')
            },

            {
                path: 're_ap_game_pay',
                name: 're_ap_game_pay',
                meta: { ...requireLoginMeta, ...requireAuthMeta },
                component: () => import(/* webpackChunkName: "re_ap_game_pay" */ '@/views/re_ap_game_pay/Index.vue')
            },

            {
                path: 'diss_room',
                name: 'diss_room',
                meta: { ...requireLoginMeta, ...requireAuthMeta },
                component: () => import(/* webpackChunkName: "diss_room" */ '@/views/diss_room/Index.vue')
            },
            
            

            {
                path: 'forbidden',
                name: 'forbidden',
                meta: { ...requireLoginMeta },
                component: Forbidden
            },
        ]
    },

    // {
    //     path: '/forbidden',
    //     name: 'forbidden',
    //     component: Forbidden
    // },

    // {
    //   path: '/about',
    //   name: 'About',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    // }
]
export default routes