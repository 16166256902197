import Auth from './Auth.vue'
import AuthButton from './AuthButton.vue'
import AuthSwitch from './AuthSwitch.vue'
import AuthIcon from './AuthIcon.vue'
import AuthTextButton from './AuthTextButton.vue';

Auth.AuthButton = AuthButton
Auth.AuthSwitch = AuthSwitch
Auth.AuthIcon = AuthIcon
Auth.AuthTextButton = AuthTextButton

export {
    Auth,
    AuthButton,
    AuthSwitch,
    AuthIcon,
    AuthTextButton
}

export default Auth