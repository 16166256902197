<template>
  <Auth :auth="auth">
    <template v-slot:default>
      <span
        v-bind="$props"
        class="l-auth-text-btn"
        :class="enabled_class"
        @click.stop="handleClick"
      >
        <slot></slot>
      </span>
    </template>

    <template v-slot:denied>
      <span class="l-auth-text-disabled" v-bind="$props" v-if="noAuthVisible">
        <slot></slot>
      </span>
    </template>
  </Auth>
</template>

<script>
import Auth from "./Auth";
import { defineComponent } from "vue";

export default defineComponent({
  name: "l-auth-text-button",
  inheritAttrs: false,
  components: {
    Auth,
  },
  props: {
    auth: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "primary",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    //没有权限时是否显示
    noAuthVisible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    enabled_class() {
      if (this.$props.disabled) {
        return "l-disabled";
      }
      switch (this.type) {
        case "normal":
          return "l-normal";
        case "primary":
          return "l-primary";
        case "danger":
          return "l-danger";
        case "warning":
          return "l-warning";
        default:
          return "l-primary";
      }
    },
  },
  data() {
    return {};
  },
  methods: {
    handleClick(e) {
      if (this.$props.disabled) {
        return;
      }

      this.$emit("click", e);
    },
  },
});
</script>

<style lang="scss" scoped>
.l-auth-text-btn {
  cursor: pointer;
  padding: 0 5px;
  &.l-primary {
    color: $primary-color;
  }
  &.l-danger {
    color: $danger-color;
  }
  &.l-warning {
    color: $warning-color;
  }

  &.l-disabled {
    color: $disabled-color;
    cursor: not-allowed;
  }
}

.l-auth-text-disabled {
  padding: 0 5px;
  color: $disabled-color;
}
</style>
