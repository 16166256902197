<template>
  <span :class="{ 'l-font-success': value > 0, 'l-font-danger': value < 0 }" v-bind="$attrs">
    <template v-if="value > 0">+ </template>
    {{ value }}
  </span>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
    },
  },
  setup() {},
};
</script>
