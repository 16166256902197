
import { computed, defineComponent, inject, reactive, Ref, ref, watch } from "vue";

export default defineComponent({
  name: "lTable",
  props: {
    columns: {
      type: Array,
      required: true,
    },
    offsetHeight: {
      type: Number,
      default: 370,
    },

    scrollXValue: {
      type: [Number, String, Boolean],
      default: 2000,
    },

    defaultTableSize: {
      type: String,
      default: "",
    },
    showActionIcon: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const fontRootValue = inject<Ref<number>>("fontRootValue");
    const height = window.innerHeight - props.offsetHeight;
    const table: any = ref(null);
    const tableSize = ref(["middle"]);
    if (props.defaultTableSize != "") {
      tableSize.value = [props.defaultTableSize];
    }

    //表格宽度
    const computedScrollXValue = computed(() => {
      if (props.scrollXValue !== 2000) {
        return props.scrollXValue;
      } else {
        let total = 0;
        props.columns.forEach((item: any) => {
          const rootValue = fontRootValue!.value || 1;
          const width = Math.ceil(rootValue * item.width);

          if (!item.width) {
            total += 100;
          } else {
            total += width;
          }
        });

        console.log(total);

        return total;
      }
    });

    //列
    const computedColums = computed(() => {
      let colums: any = [];
      props.columns.forEach((item: any) => {
        let rootValue = fontRootValue!.value || 1;
        colums.push({
          ...item,
          width: Math.ceil(rootValue * item.width),
        });
      });

      return colums;
    });

    //控制表格尺寸
    const tableSizeList = [
      {
        key: "default",
        name: "稀疏",
      },
      {
        key: "middle",
        name: "中等",
      },
      {
        key: "small",
        name: "紧凑",
      },
    ];
    //点击表格尺寸选择
    const handleSizeSelect = ({ key }: { key: string }) => {
      tableSize.value = [key];
    };

    //点击刷新按钮
    const reload = () => {
      emit("reload");
    };

    //控制列显示
    const selectColumnsList: any[] = [];
    const checkedList: any[] = [];
    props.columns.forEach((item: any) => {
      selectColumnsList.push({
        value: item.dataIndex,
        label: item.title,
      });
      checkedList.push(item.dataIndex);
    });

    const tableColumnsState = reactive({
      checkAll: true,
      indeterminate: false,
      checkedList: checkedList,
    });

    //展示的列
    const tableColumns = computed(() => {
      return computedColums.value.filter((item: any) => {
        const index = tableColumnsState.checkedList.findIndex(itm => {
          return itm == item.dataIndex;
        });

        return index != -1;
      });
    });

    //列全选
    const handleTableColumsChange = (e: any) => {
      Object.assign(tableColumnsState, {
        checkedList: e.target.checked ? checkedList : [],
        indeterminate: false,
      });
    };

    //重置列选择
    const resetTableColums = () => {
      tableColumnsState.checkedList = checkedList;
    };

    watch(
      () => tableColumnsState.checkedList,
      val => {
        tableColumnsState.indeterminate = !!val.length && val.length < props.columns.length;
        tableColumnsState.checkAll = val.length === props.columns.length;
      }
    );

    watch(tableColumns, val => {
      console.log(val);
    });

    const handleResizeColumn = (w: any, col: any) => {
      col.width = w;
    };

    return {
      height,
      table,
      tableColumns,
      selectColumnsList,
      tableSize,
      tableSizeList,
      tableColumnsState,
      computedScrollXValue,
      computedColums,
      handleSizeSelect,
      reload,
      handleTableColumsChange,
      resetTableColums,
      handleResizeColumn,
    };
  },
});
