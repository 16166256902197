<template>
  <a-config-provider :locale="zhCN">
    <router-view />
  </a-config-provider>
</template>

<script lang="ts">
import { defineComponent, onMounted, provide, ref } from "vue";

import zhCN from "ant-design-vue/es/locale/zh_CN";

export default defineComponent({
  name: "app",
  setup() {
    const mediaType = ref("xl");
    const fontRootValue = ref(1);

    //根据浏览器宽度获取响应式标识
    const getMediaTyp = () => {
      let clientWith = document.body.clientWidth;
      if (clientWith >= 1200) {
        return "lg";
      } else if (clientWith >= 992 && clientWith < 1200) {
        return "lg";
      } else if (clientWith >= 768 && clientWith < 992) {
        return "md";
      } else if (clientWith >= 576 && clientWith < 768) {
        return "md";
      } else {
        return "md";
      }
    };

    //根据浏览器宽度获取响应式式缩放值
    const getFontRootValue = () => {
      let clientWith = document.body.clientWidth;
      if (clientWith >= 1200) {
        return 1;
      } else if (clientWith >= 992 && clientWith < 1200) {
        return 11 / 14;
      } else if (clientWith >= 768 && clientWith < 992) {
        return 11 / 14;
      } else if (clientWith >= 576 && clientWith < 768) {
        return 10 / 14;
      } else {
        return 10 / 14;
      }
    };

    onMounted(() => {
      mediaType.value = getMediaTyp();
      fontRootValue.value = getFontRootValue();
    });

    provide("mediaType", mediaType);
    provide("fontRootValue", fontRootValue);

    //监听屏幕变化
    window.onresize = () => {
      return (() => {
        mediaType.value = getMediaTyp();
        fontRootValue.value = getFontRootValue();
      })();
    };

    return {
      zhCN,
    };
  },
});
</script>

<style lang="scss">
#app {
}
</style>
