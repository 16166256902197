import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getStorage, getValue } from '@/common/utils'
import store from '@/store'
import { AUTH } from '@/store/actions'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

//路由拦截
router.beforeEach((to, from, next) => {
  NProgress.start();

  const requireLogin = getValue(to.meta, 'requireLogin', false);
  const requireAuth = getValue(to.meta, 'requireAuth', false);

  const title = getValue(to.meta, 'title', null);
  if (title !== null) {
      window.document.title = title;
  }


  //先检查登录权限
  if (requireLogin) {
    const token = getStorage('stoken');
    if (!token) {
      NProgress.done();
      next('/login');
    }
  }

    //检查路由权限
    if (requireAuth) {
      //如果未从后端获取权限，则先获取权限
      const { auth }:any = store.state;
      if (!auth.is_request) {
          store.dispatch(AUTH.NAMESPACE + '/' + AUTH.REQUEST_AUTH).then(() => {
              if (!store.getters['auth/checkRoute'](to.path)) {
                  NProgress.done();
                  next({
                      path: '/console/forbidden'
                  })
              } else {
                  NProgress.done();
                  next();
              }
          })
      } else {
          if (!store.getters['auth/checkRoute'](to.path)) {
              NProgress.done();
              next({
                  path: '/console/forbidden'
              })
          } else {
              NProgress.done();
              next();
          }
      }
      // NProgress.done();
      // next();
  } else {
      NProgress.done();
      next();
  }

  // setTimeout(() => {
  //   NProgress.done();
  // }, 1000)
  // // ...
  // // 返回 false 以取消导航
  // return true
})

export default router
