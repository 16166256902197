<template>
  <a-drawer :width="500" destroyOnClose :visible="visible" :closable="false" :maskClosable="false" :body-style="{ paddingBottom: '50px' }" v-bind="$attrs" @close="handleClose">
    <template v-for="(item, key, index) in $slots" :key="index" v-slot:[key]>
      <slot :name="key"></slot>
    </template>
  </a-drawer>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "lDrawer",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const handleClose = () => {
      // emit("update:visible", false);
    };

    return {
      handleClose,
    };
  },
});
</script>
