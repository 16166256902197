interface LabelValue {
    label: string,
    value: string | number | boolean
}

//根据value获取label
export const getLabelByValue = (labelValue: LabelValue[], value: string | number | boolean): string => {
    const index = labelValue.findIndex(item => {
        return item.value == value
    })

    return index == -1 ? '' : labelValue[index].label
}

//权限类型
export const authTypes: LabelValue[] = [
    { value: 1, label: "普通权限" },
    { value: 2, label: "菜单" },
]

//隐私协议类型
export const privacyTypes: LabelValue[] = [
    { value: 1, label: "隐私政策" },
    { value: 2, label: "用户协议" },
    { value: 3, label: "游戏隐私保护协议" },
]

//游戏类型
export const HNMJ_PRI = 1 //海南麻将私人场
export const HNMJ_CLUB = 2 //海南麻将俱乐部
export const HNLZ = 3 //海南癞子麻将私人场
export const HNLZ_CLUB = 4 //海南癞子麻将俱乐部
export const SS_POKER = 5 //陵水十三张私人场
export const SS_POKER_CLUB = 6 //陵水十三张俱乐部
export const LLD_POKER = 7    //儋州地主牌私人场
export const LLD_POKER_CLUB = 8     //儋州地主牌俱乐部
export const gameTypes: LabelValue[] = [
    { value: HNMJ_PRI, label: "海南麻将私人场" },
    { value: HNMJ_CLUB, label: "海南麻将俱乐部" },
    { value: HNLZ, label: "海南癞子麻将私人场" },
    { value: HNLZ_CLUB, label: "海南癞子麻将俱乐部" },
    { value: SS_POKER, label: "陵水十三张私人场" },
    { value: SS_POKER_CLUB, label: "陵水十三张俱乐部" },
    { value: LLD_POKER, label: "儋州地主牌私人场" },
    { value: LLD_POKER_CLUB, label: "儋州地主牌俱乐部" },
]
//是否扑克
export const IsPkType = (gameType: number) => {
    return [SS_POKER, SS_POKER_CLUB, LLD_POKER, LLD_POKER_CLUB].includes(gameType);
};

//是否陵水十三张
export const IsSsPkType = (gameType: number) => {
    return [SS_POKER, SS_POKER_CLUB].includes(gameType);
};

//是否儋州地主牌
export const IsLldPkType = (gameType: number) => {
    return [LLD_POKER, LLD_POKER_CLUB].includes(gameType);
};

//海南麻将图标配置
interface HnmjCardImg {
    label: string,
    value: string | number,
    icon: string,
}
export const hnmjCardImgList: HnmjCardImg[] = [
    { label: "一万", value: 1, icon: "mjCard_tile_1.png" },
    { label: "二万", value: 2, icon: "mjCard_tile_2.png" },
    { label: "三万", value: 3, icon: "mjCard_tile_3.png" },
    { label: "四万", value: 4, icon: "mjCard_tile_4.png" },
    { label: "五万", value: 5, icon: "mjCard_tile_5.png" },
    { label: "六万", value: 6, icon: "mjCard_tile_6.png" },
    { label: "七万", value: 7, icon: "mjCard_tile_7.png" },
    { label: "八万", value: 8, icon: "mjCard_tile_8.png" },
    { label: "九万", value: 9, icon: "mjCard_tile_9.png" },

    { label: "一筒", value: 11, icon: "mjCard_tile_11.png" },
    { label: "二筒", value: 12, icon: "mjCard_tile_12.png" },
    { label: "三筒", value: 13, icon: "mjCard_tile_13.png" },
    { label: "四筒", value: 14, icon: "mjCard_tile_14.png" },
    { label: "五筒", value: 15, icon: "mjCard_tile_15.png" },
    { label: "六筒", value: 16, icon: "mjCard_tile_16.png" },
    { label: "七筒", value: 17, icon: "mjCard_tile_17.png" },
    { label: "八筒", value: 18, icon: "mjCard_tile_18.png" },
    { label: "九筒", value: 19, icon: "mjCard_tile_19.png" },

    { label: "一条", value: 21, icon: "mjCard_tile_21.png" },
    { label: "二条", value: 22, icon: "mjCard_tile_22.png" },
    { label: "三条", value: 23, icon: "mjCard_tile_23.png" },
    { label: "四条", value: 24, icon: "mjCard_tile_24.png" },
    { label: "五条", value: 25, icon: "mjCard_tile_25.png" },
    { label: "六条", value: 26, icon: "mjCard_tile_26.png" },
    { label: "七条", value: 27, icon: "mjCard_tile_27.png" },
    { label: "八条", value: 28, icon: "mjCard_tile_28.png" },
    { label: "九条", value: 29, icon: "mjCard_tile_29.png" },

    { label: "东", value: 31, icon: "mjCard_tile_31.png" },
    { label: "南", value: 32, icon: "mjCard_tile_32.png" },
    { label: "西", value: 33, icon: "mjCard_tile_33.png" },
    { label: "北", value: 34, icon: "mjCard_tile_34.png" },
    { label: "中", value: 35, icon: "mjCard_tile_35.png" },
    { label: "发", value: 36, icon: "mjCard_tile_36.png" },
    { label: "白", value: 37, icon: "mjCard_tile_37.png" },

    { label: "春", value: 41, icon: "mjCard_tile_41.png" },
    { label: "夏", value: 42, icon: "mjCard_tile_42.png" },
    { label: "秋", value: 43, icon: "mjCard_tile_43.png" },
    { label: "冬", value: 44, icon: "mjCard_tile_44.png" },

    { label: "梅", value: 51, icon: "mjCard_tile_51.png" },
    { label: "兰", value: 52, icon: "mjCard_tile_52.png" },
    { label: "菊", value: 53, icon: "mjCard_tile_53.png" },
    { label: "竹", value: 54, icon: "mjCard_tile_54.png" },
]


//扑克图标配置
interface PokerCardImg {
    label: string,
    value: string | number,
    icon: string,
}
export const pokerCardImgList: PokerCardImg[] = [
    // { label: "牌底", value: 0, icon: "pkCard_0.png" },
    { label: "方块A", value: 1, icon: "pkCard_1.png" },
    { label: "方块2", value: 2, icon: "pkCard_2.png" },
    { label: "方块3", value: 3, icon: "pkCard_3.png" },
    { label: "方块4", value: 4, icon: "pkCard_4.png" },
    { label: "方块5", value: 5, icon: "pkCard_5.png" },
    { label: "方块6", value: 6, icon: "pkCard_6.png" },
    { label: "方块7", value: 7, icon: "pkCard_7.png" },
    { label: "方块8", value: 8, icon: "pkCard_8.png" },
    { label: "方块9", value: 9, icon: "pkCard_9.png" },
    { label: "方块10", value: 10, icon: "pkCard_10.png" },
    { label: "方块J", value: 11, icon: "pkCard_11.png" },
    { label: "方块Q", value: 12, icon: "pkCard_12.png" },
    { label: "方块K", value: 13, icon: "pkCard_13.png" },

    { label: "梅花A", value: 21, icon: "pkCard_21.png" },
    { label: "梅花2", value: 22, icon: "pkCard_22.png" },
    { label: "梅花3", value: 23, icon: "pkCard_23.png" },
    { label: "梅花4", value: 24, icon: "pkCard_24.png" },
    { label: "梅花5", value: 25, icon: "pkCard_25.png" },
    { label: "梅花6", value: 26, icon: "pkCard_26.png" },
    { label: "梅花7", value: 27, icon: "pkCard_27.png" },
    { label: "梅花8", value: 28, icon: "pkCard_28.png" },
    { label: "梅花9", value: 29, icon: "pkCard_29.png" },
    { label: "梅花10", value: 30, icon: "pkCard_30.png" },
    { label: "梅花J", value: 31, icon: "pkCard_31.png" },
    { label: "梅花Q", value: 32, icon: "pkCard_32.png" },
    { label: "梅花K", value: 33, icon: "pkCard_33.png" },

    { label: "红桃A", value: 41, icon: "pkCard_41.png" },
    { label: "红桃2", value: 42, icon: "pkCard_42.png" },
    { label: "红桃3", value: 43, icon: "pkCard_43.png" },
    { label: "红桃4", value: 44, icon: "pkCard_44.png" },
    { label: "红桃5", value: 45, icon: "pkCard_45.png" },
    { label: "红桃6", value: 46, icon: "pkCard_46.png" },
    { label: "红桃7", value: 47, icon: "pkCard_47.png" },
    { label: "红桃8", value: 48, icon: "pkCard_48.png" },
    { label: "红桃9", value: 49, icon: "pkCard_49.png" },
    { label: "红桃10", value: 50, icon: "pkCard_50.png" },
    { label: "红桃J", value: 51, icon: "pkCard_51.png" },
    { label: "红桃Q", value: 52, icon: "pkCard_52.png" },
    { label: "红桃K", value: 53, icon: "pkCard_53.png" },

    { label: "黑桃A", value: 61, icon: "pkCard_61.png" },
    { label: "黑桃2", value: 62, icon: "pkCard_62.png" },
    { label: "黑桃3", value: 63, icon: "pkCard_63.png" },
    { label: "黑桃4", value: 64, icon: "pkCard_64.png" },
    { label: "黑桃5", value: 65, icon: "pkCard_65.png" },
    { label: "黑桃6", value: 66, icon: "pkCard_66.png" },
    { label: "黑桃7", value: 67, icon: "pkCard_67.png" },
    { label: "黑桃8", value: 68, icon: "pkCard_68.png" },
    { label: "黑桃9", value: 69, icon: "pkCard_69.png" },
    { label: "黑桃10", value: 70, icon: "pkCard_70.png" },
    { label: "黑桃J", value: 71, icon: "pkCard_71.png" },
    { label: "黑桃Q", value: 72, icon: "pkCard_72.png" },
    { label: "黑桃k", value: 73, icon: "pkCard_73.png" },
]


//根据value获取icon
export const getIconByValue = (labelValue: HnmjCardImg[], value: string | number | boolean): string => {
    const index = labelValue.findIndex(item => {
        return item.value == value
    })

    return index == -1 ? '' : labelValue[index].icon
}

//货币变化类型
export const NonType = 0 //无(忽略)
export const PayType = 1 //在线支付充值
export const GiveType = 2 //系统奖励/赠送(忽略)
export const NewUserType = 3 //新用户注册奖励
export const CompenstateType = 4 //系统补偿(忽略)
export const RoomType = 5 //海南麻将私人场房卡消耗
export const GobangType = 6 //五子棋消耗
export const ClubHnmjType = 7 //海南麻将俱乐部消耗
export const HnlzType = 8 //海南LZ麻将私人场消耗
export const ClubHnlz = 9 //海南LZ麻将俱乐部消耗
export const SsPoker = 10 //陵水十三张
export const ClubBSsPoker = 11 //陵水十三张CLUB
export const LldPoker = 12 //儋州地主牌
export const ClubLldPoker = 13 //儋州地主牌CLUB

// export const ActivityType = 10 //活动(忽略)

export const CoinsAccessType = 50 //保险柜
export const CoinsChangeType = 51 //货币修改（后台）
export const SysBenefitType = 52 //救济金
export const PointConvert = 53 //福气兑换

export const BugBebackType = 55 //bug反馈
export const JdFail = 56 //京东审核失败

export const LoginContinueType = 61 //连续登录奖励
export const BindAccountType = 62 //绑定账号
export const RechargeType = 63 //充值返利
export const BindAgent = 64 //绑定代理

export const AgentPresentType = 71 //代理转赠道具

export const CurrencyRechargeType: LabelValue[] = [
    { value: NonType, label: "无" },
    { value: PayType, label: "在线支付充值" },
    { value: GiveType, label: "系统奖励/赠送" },
    { value: NewUserType, label: "新用户注册奖励" },
    { value: CompenstateType, label: "系统补偿" },
    { value: RoomType, label: "海南麻将私人场房卡消耗" },
    { value: GobangType, label: "五子棋消耗" },
    { value: ClubHnmjType, label: "海南麻将俱乐部消耗" },
    { value: HnlzType, label: "海南LZ麻将私人场消耗" },
    { value: ClubHnlz, label: "海南LZ麻将俱乐部消耗" },
    { value: SsPoker, label: "陵水十三张" },
    { value: ClubBSsPoker, label: "陵水十三张CLUB" },
    { value: LldPoker, label: "儋州地主牌" },
    { value: ClubLldPoker, label: "儋州地主牌CLUB" },

    // { value: ActivityType, label: "活动" },

    { value: CoinsAccessType, label: "保险柜" },
    { value: CoinsChangeType, label: "货币修改（后台）" },
    { value: SysBenefitType, label: "救济金" },
    { value: PointConvert, label: "福气兑换" },

    { value: BugBebackType, label: "bug反馈" },
    { value: JdFail, label: "京东审核失败" },

    { value: LoginContinueType, label: "连续登录奖励" },
    { value: BindAccountType, label: "绑定账号" },
    { value: RechargeType, label: "充值返利" },
    { value: BindAgent, label: "绑定代理" },

    { value: AgentPresentType, label: "代理转赠道具" },
]

//货币类型
export const beansCoin = 104//钻石
export const goldCoin = 105//金币

//修改货币可选择的货币类型
export const GoodsTypes: LabelValue[] = [
    { value: beansCoin, label: "钻石" },
    { value: goldCoin, label: "金币" },
]

//邮件类型
export const mailTypes: LabelValue[] = [
    { value: 1, label: "普通邮件" },
    { value: 2, label: "奖励邮件" },
]

//发送类型
export const mailSendTypes: LabelValue[] = [
    { value: 1, label: "发给单个玩家" },
    { value: 2, label: "发给多个玩家" },
    { value: 3, label: "发给所有玩家" },
]

//可发送邮件的奖励类型
export const mailAwardTypes: LabelValue[] = [
    { value: PayType, label: "在线支付充值" },
    { value: NewUserType, label: "新用户注册奖励" },
    { value: BugBebackType, label: "bug反馈" },
    { value: LoginContinueType, label: "连续登录奖励" },
    { value: BindAccountType, label: "绑定账号" },
    // {value:BindAgent,label:"绑定代理"},
]

//可发送邮件的货币类型
export const mailGoodsIds: LabelValue[] = [
    { value: beansCoin, label: "钻石" },
    { value: goldCoin, label: "金币" },
]


//邮件处理状态
export const mailStatus: LabelValue[] = [
    { value: 1, label: "处理中" },
    { value: 2, label: "处理成功" },
    { value: 3, label: "处理失败" },
]

//平台类型
export const paltformTypes: LabelValue[] = [
    { value: 1, label: "安卓" },
    { value: 2, label: "苹果" },
]

//区域类型
export const areaTypes: LabelValue[] = [
    { value: 1, label: "海南" },
    { value: 2, label: "山西" },
    { value: 3, label: "海南(新)" },
    { value: 4, label: "海南(新)业务员端" },
]


//性别
export const sexTypes: LabelValue[] = [
    { value: 0, label: "无" },
    { value: 1, label: "男" },
    { value: 2, label: "女" },
]



//活动类型
export const ActyUpdateType = 50 //更新公告
export const ActyCenter = 51 //客服中心
export const ActyGame = 52 //健康游戏公告
export const ActyStatement = 53 //作弊声明
export const ActyTypes: LabelValue[] = [
    { value: ActyUpdateType, label: "更新公告" },
    { value: ActyCenter, label: "客服中心" },
    { value: ActyGame, label: "健康游戏公告" },
    { value: ActyStatement, label: "作弊声明" },
]


//活动标签
export const ActyLabelNonLabelType = 1 //空
export const ActyLabelTimeLabelType = 2 //限时
export const ActyLabelStormLabelType = 3 //火爆
export const ActyLabelNoticeLabelType = 4 //公告
export const ActyLabelActivityLabelType = 5 //活动
export const ActyLabelTypes: LabelValue[] = [
    { value: ActyLabelNonLabelType, label: "空" },
    { value: ActyLabelTimeLabelType, label: "限时" },
    { value: ActyLabelStormLabelType, label: "火爆" },
    { value: ActyLabelNoticeLabelType, label: "公告" },
    { value: ActyLabelActivityLabelType, label: "活动" },
]

//游戏活动是否跳转到外部链接
export const NonImgMode = 0
export const LinkImgMode = 1
export const RechargeImgMode = 2

export const imgModeTypes: LabelValue[] = [
    { value: NonImgMode, label: "无" },
    { value: LinkImgMode, label: "跳转到外链接" },
    { value: RechargeImgMode, label: "跳转到充值列表" },
]


//充值表物品类型
export const GoldMallType = 1;
export const BeanMallType = 2;
export const mallGoodsTypes: LabelValue[] = [
    { value: GoldMallType, label: "金币" },
    { value: BeanMallType, label: "钻石" },
]

//充值物品图标
interface MallImgList {
    value: string | number,
    icon: string,
}
export const mallBearnsImgList: MallImgList[] = [
    { value: "1", icon: "bearns1.png" },
    { value: "2", icon: "bearns2.png" },
    { value: "3", icon: "bearns3.png" },
    { value: "4", icon: "bearns4.png" },
    { value: "5", icon: "bearns5.png" },
]

export const mallGoldImgList: MallImgList[] = [
    { value: "1", icon: "gold1.png" },
    { value: "2", icon: "gold2.png" },
    { value: "3", icon: "gold3.png" },
    { value: "4", icon: "gold4.png" },
    { value: "5", icon: "gold5.png" },
    // { value: "6", icon: "gold6.png" },
]

//根据value获取icon
export const getImgByValue = (labelValue: MallImgList[], value: string | number | boolean): string => {
    const index = labelValue.findIndex(item => {
        return item.value == value
    })

    return index == -1 ? '' : labelValue[index].icon
}


//活动类型
export const AcActyTypeLogin = 1//登录有礼
export const AcActyTypeShow = 2//显示活动
export const AcActyTypeRecharge = 3//充值返利
export const AcActyTypes: LabelValue[] = [
    { value: AcActyTypeLogin, label: "登录活动" },
    { value: AcActyTypeShow, label: "显示banner" },
    { value: AcActyTypeRecharge, label: "充值返利" },
]

//功能性质
export const AcActyNatureImg = 1 //显示图片
export const AcActyNaturePresent = 2 //领取奖励
export const AcActyNatures: LabelValue[] = [
    { value: AcActyNatureImg, label: "显示图片" },
    { value: AcActyNaturePresent, label: "领取奖励" },
]


//可用于活动的货币类型
export const ActyGoodsIds: LabelValue[] = [
    { value: beansCoin, label: "钻石" },
    { value: goldCoin, label: "金币" },
]


//私人场消费类型
export const RoomChargeNone = 0//无需消费
export const RoomChargeNoShare = 1//房主
export const RoomChargeShare = 2//房费均摊
export const RoomChargeMaster = 3//馆主
export const roomChargeTypes: LabelValue[] = [
    { value: RoomChargeNone, label: "无需消费" },
    { value: RoomChargeNoShare, label: "房主" },
    { value: RoomChargeShare, label: "房费均摊" },
    { value: RoomChargeMaster, label: "馆主" },
]

//玩家数量
export const GamePlayerTypeDouble = 2//两人
export const GamePlayerTypeThree = 3//三人
export const GamePlayerTypeFour = 4//四人
export const gamePlayerTypes: LabelValue[] = [
    { value: GamePlayerTypeDouble, label: "2人" },
    { value: GamePlayerTypeThree, label: "3人" },
    { value: GamePlayerTypeFour, label: "4人" },
]

//**************桌子规则*******************
//是否有番
export const DeskIsFan = true//有番
export const DeskNotFan = false//无番
export const DeskFanList: LabelValue[] = [
    { value: DeskIsFan, label: "有番" },
    { value: DeskNotFan, label: "无番" },
]

//癞子
export const RandLz = true//随机癞子
export const HzLz = false//红中癞子
export const IsRandLzList: LabelValue[] = [
    { value: RandLz, label: "随机癞子" },
    { value: HzLz, label: "红中癞子" },
]


//**********托管解散方式*********** */
export const AutoCountDissRound = 1//小局结束解散
export const AutoCountDissGroup = 2//大局结束后解散
export const autoCountDissTypes: LabelValue[] = [
    { value: AutoCountDissRound, label: "小局结束解散" },
    { value: AutoCountDissGroup, label: "大局结束后解散" },
]


//******玩家动作******/
export const ActTypeDiscard = 0//弃牌
export const ActTypeEat = 1//吃
export const ActTypePeng = 2//碰
export const ActTypeGang = 3//杠
export const ActTypeGGangAfterPeng = 4//碰后杠
export const ActTypeAnGang = 5//暗杠
export const ActTypeHu = 6//胡
export const ActTypePickUp = 7//摸牌
export const ActTypeDeal = 8//发牌
export const ActTypeEnsureDiscard = 9//确认弃牌
export const ActTypeFlower = 10//补花
export const ActTypeIsTrusteeshipr = 11//托管
export const ActTypeGiveUp = 12//过
export const actTypes: LabelValue[] = [
    { value: ActTypeDiscard, label: "弃牌" },
    { value: ActTypeEat, label: "吃" },
    { value: ActTypePeng, label: "碰" },
    { value: ActTypeGang, label: "杠" },
    { value: ActTypeGGangAfterPeng, label: "碰后杠" },
    { value: ActTypeAnGang, label: "暗杠" },
    { value: ActTypeHu, label: "胡" },
    { value: ActTypePickUp, label: "摸牌" },
    { value: ActTypeDeal, label: "发牌" },
    { value: ActTypeEnsureDiscard, label: "确认弃牌" },
    { value: ActTypeFlower, label: "补花" },
    { value: ActTypeIsTrusteeshipr, label: "托管" },
    { value: ActTypeGiveUp, label: "过" },
]


//扑克动作
export const pokerActTypeDeal = 1;
export const pokerActTypeDiscard = 2;
export const pokerActTypeGiveUp = 3;
export const pokerActTypeEndRound = 4;
export const pokerActTypeIsTrusteeship = 5;
export const PokerActTypes: LabelValue[] = [
    { value: pokerActTypeDeal, label: "发牌" },
    { value: pokerActTypeDiscard, label: "出牌" },
    { value: pokerActTypeGiveUp, label: "过牌" },
    { value: pokerActTypeEndRound, label: "结算" },
    { value: pokerActTypeIsTrusteeship, label: "托管" },
]

// poker_cons.actType = {
//     Deal = 1,               --发牌
//     Discard = 2,            --弃牌
//     GiveUp = 3,             --过牌
//     EndRound = 4,           --结算
//     isTrusteeship = 5,      --是否托管
// }

//**************亲友圈是否营业*******************
export const ClubBusinessStatusOff = 0//打烊
export const ClubBusinessStatusOn = 1//营业
export const clubBusinessStatus: LabelValue[] = [
    { value: ClubBusinessStatusOn, label: "营业" },
    { value: ClubBusinessStatusOff, label: "打烊" },
]


//*****************俱乐部模式****************/
export const ClubBusinessModeNormal = 1//正常模式
export const ClubBusinessModeCompetition = 2//比赛模式
export const ClubBusinessMode: LabelValue[] = [
    { value: ClubBusinessModeNormal, label: "正常模式" },
    { value: ClubBusinessModeCompetition, label: "比赛模式" },
]


//******************俱乐部身份******************* */
export const ClubMemberIdentityPresident = 1  //会长
export const ClubMemberIdentityVicePresident = 2  //副会长
export const ClubMemberIdentityAgent = 5  //代理
export const ClubMemberIdentityManager = 6  //管理员
export const ClubMemberIdentityMember = 10 //成员
export const clubMemberIdentitys: LabelValue[] = [
    { value: ClubMemberIdentityPresident, label: "会长" },
    { value: ClubMemberIdentityVicePresident, label: "副会长" },
    { value: ClubMemberIdentityAgent, label: "代理" },
    { value: ClubMemberIdentityManager, label: "管理员" },
    { value: ClubMemberIdentityMember, label: "成员" },
]

//后台可选择的身份列表
export const clubMemberSelAbleIdentitys: LabelValue[] = [
    { value: ClubMemberIdentityVicePresident, label: "副会长" },
    { value: ClubMemberIdentityAgent, label: "代理" },
    { value: ClubMemberIdentityManager, label: "管理员" },
    { value: ClubMemberIdentityMember, label: "成员" },
]

//***************俱乐部审核状态************* */
export const ClubApplyUnchecked = 0  //待审核
export const ClubApplyPass = 1  //通过
export const ClubApplyReject = 2  //拒绝
export const clubApplyStates: LabelValue[] = [
    { value: ClubApplyUnchecked, label: "待审核" },
    { value: ClubApplyPass, label: "通过" },
    { value: ClubApplyReject, label: "拒绝" },
]

//***************俱乐部审核状态************* */
export const ClubAgencyApplyUnchecked = 0  //待审核
export const ClubAgencyApplyPass = 1  //通过
export const ClubAgencyApplyReject = 2  //拒绝
export const clubAgencyApplyStates: LabelValue[] = [
    { value: ClubApplyUnchecked, label: "待处理" },
    { value: ClubApplyPass, label: "通过" },
    { value: ClubApplyReject, label: "拒绝" },
]

//************亲友圈空桌显示类型************* */
export const NullDeskTypeFront = 1  //空桌在前
export const NullDeskTypeMiddle = 2  //空桌在中
export const NullDeskTypeLast = 3  //空桌在后
export const nullDeskTypes: LabelValue[] = [
    { value: NullDeskTypeFront, label: "空桌在前" },
    { value: NullDeskTypeMiddle, label: "空桌在中" },
    { value: NullDeskTypeLast, label: "空桌在后" },
]

//************亲友圈满桌显示类型************* */
export const FullDeskTypeFull = 1  //全部显示(1000桌)
export const NullDeskTypeFive = 2  //5桌
export const NullDeskTypeTen = 3  //10桌
export const NullDeskTypeTwenty = 4  //20桌
export const NullDeskTypeThirty = 5  //30桌
export const fullDeskTypes: LabelValue[] = [
    { value: FullDeskTypeFull, label: "全部显示(1000桌)" },
    { value: NullDeskTypeFive, label: "5桌" },
    { value: NullDeskTypeTen, label: "10桌" },
    { value: NullDeskTypeTwenty, label: "20桌" },
    { value: NullDeskTypeThirty, label: "30桌" },
]


//***************俱乐部日志类型************* */
export const ClubLogTypeRule = 1  //规则/玩法日志
export const ClubLogTypeMember = 2  //成员日志
export const ClubLogTypeClub = 3  //亲友圈日志
export const ClubLogTypeBlanklist = 4  //小黑屋日志
export const clubLogTypes: LabelValue[] = [
    { value: ClubLogTypeRule, label: "规则/玩法日志" },
    { value: ClubLogTypeMember, label: "成员日志" },
    { value: ClubLogTypeClub, label: "亲友圈日志" },
    { value: ClubLogTypeBlanklist, label: "小黑屋日志" },
]


//**********后台俱乐部操作日志************ */
export const GmClubOprLogTypeCreate = 1
export const GmClubOprLogTypeDelete = 2
export const GmClubOprLogTypeUpdIdentity = 3
export const GmClubOprLogTypeUpdBind = 4
export const GmClubOprLogTypeUpdRulePercent = 5
export const GmClubOprLogTypeUpdRuleClean = 6
export const GmClubOprLogTypeBlackRoom = 7
export const gmClubOprLogTypes: LabelValue[] = [
    { value: GmClubOprLogTypeCreate, label: "添加成员" },
    { value: GmClubOprLogTypeDelete, label: "解除绑定" },
    { value: GmClubOprLogTypeUpdIdentity, label: "更改身份" },
    { value: GmClubOprLogTypeUpdBind, label: "关系转移" },
    { value: GmClubOprLogTypeUpdRulePercent, label: "修改比例" },
    { value: GmClubOprLogTypeUpdRuleClean, label: "清除比例" },
    { value: GmClubOprLogTypeBlackRoom, label: "小黑屋" },
]


//**********体力变化类型************ */
export const ClubHpChangeTypeReport = 1
export const ClubHpChangeTypeAdminset = 2
export const ClubHpChangeTypeSettle = 3
export const ClubHpChangeTypeCost = 4
export const ClubHpChangeTypeInterest = 5
export const ClubHpChangeTypeSystemclear = 7
export const ClubHpChangeTypeClear = 8
export const ClubHpChangeTypeExclear = 9
export const ClubHpChangeTypeProtect = 10
export const ClubHpChangeTypeExcost = 11
export const ClubHpChangeTypeScorerecycle = 12
export const ClubHpChangeTypeScorespend = 13
export const ClubHpChangeTypeScoreSafeRecycle = 20
export const ClubHpChangeTypeScoreDeposit = 21
export const ClubHpChangeTypeScoreTakeOut = 22

export const clubHpChangeTypes: LabelValue[] = [
    { value: ClubHpChangeTypeReport, label: "上报排名" },
    { value: ClubHpChangeTypeAdminset, label: "淘汰分设置" },
    { value: ClubHpChangeTypeSettle, label: "对局胜负" },
    { value: ClubHpChangeTypeCost, label: "门票消耗" },
    { value: ClubHpChangeTypeInterest, label: "获得收益" },
    { value: ClubHpChangeTypeSystemclear, label: "系统清理" },
    { value: ClubHpChangeTypeClear, label: "数据清零" },
    { value: ClubHpChangeTypeExclear, label: "冗余清除" },
    { value: ClubHpChangeTypeProtect, label: "负分保护" },
    { value: ClubHpChangeTypeExcost, label: "大赢家支付" },
    { value: ClubHpChangeTypeScorerecycle, label: "分值回收" },
    { value: ClubHpChangeTypeScorespend, label: "分值分配" },
    { value: ClubHpChangeTypeScoreSafeRecycle, label: "回收<藏>" },
    { value: ClubHpChangeTypeScoreDeposit, label: "存入<藏>" },
    { value: ClubHpChangeTypeScoreTakeOut, label: "取出<藏>" },
]

//藏宝阁变化记录
export enum SafeHpLogsType{
    Deposit=1,
    TakeOut=2
}

export const SafeHpLogsTypes:LabelValue[]=[
    { value: SafeHpLogsType.Deposit, label: "存入" },
    { value: SafeHpLogsType.TakeOut, label: "取出" },
]



//后台角色类型
export enum RoleUseType {
    Console = 1,
    Salesman = 2
}

export const RoleUseTypes: LabelValue[] = [
    { value: RoleUseType.Console, label: "主后台" },
    { value: RoleUseType.Salesman, label: "业务员后台" },
]


//支付类型
export enum ApGamePayType {
    Sand = 2,
    SandH5 = 3,
}

export const ApGamePayTypes: LabelValue[] = [
    { value: ApGamePayType.Sand, label: "杉德扫码" },
    { value: ApGamePayType.SandH5, label: "杉德H5收银台" },
]

//支付状态
export enum ApGamePayStatus {
    NotPay = 1,
    Success = 2
}

export const ApGamePayStatusList: LabelValue[] = [
    { value: ApGamePayStatus.NotPay, label: "未支付" },
    { value: ApGamePayStatus.Success, label: "支付成功" },
]


//排名赛支付方式
export enum  MatchPayType{
    BigWinnerPay=1,
    AAPay=2
}
export const MatchPayTypeList: LabelValue[] = [
    { value: MatchPayType.BigWinnerPay, label: "大赢家支付" },
    { value: MatchPayType.AAPay, label: "AA支付" },
]

//开的局数
export const RuleRoundCountList: LabelValue[] = [
    { value: 1, label: "1局" },
    { value: 4, label: "4局" },
    { value: 8, label: "8局" },
    { value: 16, label: "16局" },
]


//封顶倍数
export const RuleRcappingRateList: LabelValue[] = [
    { value: 1, label: "1局" },
    { value: 4, label: "4局" },
    { value: 8, label: "8局" },
    { value: 16, label: "16局" },
]


//海南麻将玩法列表
interface RulePlayTypeListInterface{
    key:string;
    label:string;
}

// request {
//     roundCount 0: integer       #一场开的总局数 1/4/8/16
//     playerType 1: integer       #玩家类型 2/3/4人
//     payType 2: integer          #房主/均摊/馆主 h.roomChargeType
//     basePoint 3: integer                 #底分 (0.1~100)
    
//     isFan 4: boolean                     #有番/无番  true/false
//     cappingRate 5: integer                 #封顶倍率  8/12/16倍 

//     isDealer 6: boolean                 #是否庄闲
//     isRTD 7: boolean                         #是否连庄 (remaining the dealer)

//     isUpG 8: boolean                         #是否上噶
//     setNumG 9 : integer                 #上多少噶 mj.setNumG
    
//     isDraw 12: boolean                         #是否流局/黄庄算分
//     isFlower 13: boolean                 #是否花胡
//     isFlowerDoub 14: boolean         #是否花胡翻倍
//     isChaosColorDoub 15: boolean #是否混一色翻倍
//     isNotDAW 16: boolean                #是否无字牌 (DragonAndWind 风牌和箭牌)
//     isToken 17: boolean                 #是否叫令
//     isHook 18: boolean                         #是否防勾脚
//     isSeabed 19: boolean                 #是否海底包牌
//     isThreeFour 20: boolean                #是否三道/四道包牌
//     isPengExposed 21: boolean         #碰后杠翻倍
//     isRandomDealer 22: boolean  #是否随机庄家
//     isRandomPosIndex 23: boolean #是否随机换位
//     isRead 24:boolean                        #是否自动准备
//     isDissAll 25: boolean                 #解散需要所有人同意
//         isNotEat 26: boolean                 #是否不可吃牌
//         isNotPao 27: boolean                 #是否不可点炮

//         isAuto 28:integer                        #托管时间<0/nil代表不托管  30/60/120/180 托管秒数>
//         isAutoCountDiss 29: integer #托管解散 <1 小局结束解散，2 大局结束后解散>
//         isAuthCount 30: integer         #托管局数
// }


//海南麻将4人
export const Hnmj4RulePlayTypeList:RulePlayTypeListInterface[]=[
    {key:"isDealer",label:"庄闲"},
    {key:"isRTD",label:"连庄"},
    {key:"isDraw",label:"流局算分"},
    {key:"isRandomDealer",label:"随机庄家"},
    {key:"isUpG",label:"上噶"},
    {key:"autoUpG",label:"自由上噶"},//这个需要根据是否上噶，和上的噶数是0判断
    {key:"autoUpG3",label:"首局上3噶"},//setNumG是3且isUpG=true
    {key:"autoUpG5",label:"首局上5噶"},//setNumG是5且isUpG=true
    {key:"isFlower",label:"花胡"},
    {key:"isFlowerDoub",label:"花胡翻倍"},
    {key:"isChaosColorDoub",label:"混一色翻倍"},
    {key:"isPengExposed",label:"摸杠翻倍"},
    {key:"isNotDAW",label:"无字牌"},
    {key:"isToken",label:"叫令"},
    {key:"isHook",label:"防勾脚"},
    {key:"isSeabed",label:"海底包牌"},
    {key:"isRead",label:"准备"},//true 自动准备 false 不自动准备
    {key:"isRandomPosIndex",label:"随机换座"},
    {key:"isRandomPosIndex",label:"解散需所有人同意"},
]

//海南麻将3人规则玩法
export const Hnmj3RulePlayTypeList:RulePlayTypeListInterface[]=[
    {key:"isDealer",label:"庄闲"},
    {key:"isRTD",label:"连庄"},
    {key:"isDraw",label:"流局算分"},
    {key:"isRandomDealer",label:"随机庄家"},
    {key:"isUpG",label:"上噶"},
    {key:"autoUpG",label:"自由上噶"},//这个需要根据是否上噶，和上的噶数是0判断
    {key:"autoUpG3",label:"首局上3噶"},//setNumG是3且isUpG=true
    {key:"autoUpG5",label:"首局上5噶"},//setNumG是5且isUpG=true
    {key:"isFlower",label:"花胡"},
    {key:"isFlowerDoub",label:"花胡翻倍"},
    {key:"isChaosColorDoub",label:"混一色翻倍"},
    {key:"isPengExposed",label:"摸杠翻倍"},
    {key:"isNotDAW",label:"无字牌"},
    {key:"isToken",label:"叫令"},
    {key:"isNotEat",label:"不可吃"},
    {key:"isSeabed",label:"海底包牌"},
    {key:"isRead",label:"准备"},//true 自动准备 false 不自动准备
]

//海南麻将2人规则玩法
export const Hnmj2RulePlayTypeList:RulePlayTypeListInterface[]=[
    {key:"isDealer",label:"庄闲"},
    {key:"isRTD",label:"连庄"},
    {key:"isDraw",label:"流局算分"},
    {key:"isRandomDealer",label:"随机庄家"},
    {key:"isUpG",label:"上噶"},
    {key:"autoUpG",label:"自由上噶"},//这个需要根据是否上噶，和上的噶数是0判断
    {key:"autoUpG3",label:"首局上3噶"},//setNumG是3且isUpG=true
    {key:"autoUpG5",label:"首局上5噶"},//setNumG是5且isUpG=true
    {key:"isFlower",label:"花胡"},
    {key:"isFlowerDoub",label:"花胡翻倍"},
    {key:"isChaosColorDoub",label:"混一色翻倍"},
    {key:"isPengExposed",label:"摸杠翻倍"},
    {key:"isNotDAW",label:"无字牌"},
    {key:"isToken",label:"叫令"},
    {key:"isNotEat",label:"不可吃"},
    {key:"isNotPao",label:"不可点炮"},
    {key:"isRead",label:"准备"},//true 自动准备 false 不自动准备
]

//海南麻将癞子麻将规则玩法
export const HnmjLzRulePlayTypeList:RulePlayTypeListInterface[]=[
    {key:"isDealer",label:"庄闲"},
    {key:"isRTD",label:"连庄"},
    {key:"isDraw",label:"流局算分"},
    {key:"isRandomDealer",label:"随机庄家"},
    {key:"isUpG",label:"上噶"},
    {key:"autoUpG",label:"自由上噶"},//这个需要根据是否上噶，和上的噶数是0判断
    {key:"autoUpG3",label:"首局上3噶"},//setNumG是3且isUpG=true
    {key:"autoUpG5",label:"首局上5噶"},//setNumG是5且isUpG=true
    {key:"isFlower",label:"花胡"},
    {key:"isFlowerDoub",label:"花胡翻倍"},
    {key:"isChaosColorDoub",label:"混一色翻倍"},
    {key:"isPengExposed",label:"摸杠翻倍"},
    {key:"isNotDAW",label:"无字牌"},
    {key:"isToken",label:"叫令"},
    {key:"isNotEat",label:"不可吃"},
    {key:"isNotPao",label:"不可点炮"},
    {key:"isHook",label:"防勾脚"},
    {key:"isSeabed",label:"海底包牌"},
    {key:"isRead",label:"准备"},//true 自动准备 false 不自动准备
    {key:"isRandomPosIndex",label:"解散需所有人同意"},
]

//陵水十三张玩法规则
export const SsPokerRulePlayTypeList:RulePlayTypeListInterface[]=[
    {key:"isModelAward",label:"牌型奖励"},
    {key:"isSpecialModel",label:"特殊牌型"},
    {key:"isAdd",label:"未出黑2加50分"},
    {key:"isDouble",label:"未出黑2加倍"},
    {key:"isShowPoker",label:"显示牌数"},
    {key:"isMemoryCard",label:"记牌器"},
    {key:"isRead",label:"准备"},
]

//儋州地主牌玩法规则
export const LldPokerRulePlayTypeList:RulePlayTypeListInterface[]=[
    {key:"isHeartK",label:"同花K为大"},
    {key:"isZhuaDing",label:"抓钉"},
    {key:"isAward",label:"牌型奖励"},
    {key:"isCardsNum",label:"显示牌数"},
    {key:"isSingle",label:"报单"},
]


//托管
export const RuleIsAutoList: LabelValue[] = [
    { value: 1, label: "30" },
    { value: 2, label: "60" },
    { value: 3, label: "120" },
    { value: 4, label: "180" },
]
