import { AUTH as AUTH_MUTATIONS } from '../mutations'
import { AUTH as AUTH_ACTIONS } from '../actions'
import http from '@/common/http'
import { RoleUseType } from '@/common/head'

const state = {
    loading: false,//是否正在发起获取权限的请求
    is_request: false,//是否已经向后端发起过请求
    menus: [],   //菜单
    routes: [],  //路由
    btns: []     //按钮
}

const getters = {
    //检查路由是否有权限
    checkRoute: (state:any) => (route:any) => {
        return state.routes.findIndex((item:any) => item.value === route) !== -1;
    },

    //检查按钮是否有权限
    checkBtn: (state:any) => (btn:any) => {
        if (btn.indexOf("||") != -1) {
            const btns = btn.split("||");
            let exist = false;
            state.btns.forEach((item:any) => {
                const index = btns.findIndex((itm:any) => {
                    return item.value === itm;
                });
                if (index != -1) {
                    exist = true;
                }
            })

            return exist;

        } else {
            const result= state.btns.findIndex((item:any) =>{            
                return item.value === btn
            } ) !== -1;
            return  result
            
        }
    }
}

const mutations = {
    //修改加载状态
    [AUTH_MUTATIONS.SET_LOADING](state:any, loading:any) {
        state.loading = loading;
    },

    //修改请求状态
    [AUTH_MUTATIONS.SET_IS_REQUEST](state:any, is_request:any) {
        state.is_request = is_request;
    },

    //修改路由列表
    [AUTH_MUTATIONS.SET_ROUTES](state:any, routes:any) {
        state.routes = routes;
    },

    //修改菜单列表
    [AUTH_MUTATIONS.SET_MENUS](state:any, menus:any) {
        state.menus = menus;
    },

    //修改按钮列表
    [AUTH_MUTATIONS.SET_BTNS](state:any, btns:any) {
        state.btns = btns;
    }
}

const actions = {
    [AUTH_ACTIONS.REQUEST_AUTH]({ commit }:any) {
        commit(AUTH_MUTATIONS.SET_LOADING, true);

        return new Promise((resolve, reject) => {
    
                 http.get("auth/btn_route",{useType:RoleUseType.Console}).then(ret=>{
                    const btns:any=[];
                    const routes:any=[];

                    ret.data.data.forEach((item:any)=>{
                        if(item.type==2){//路由
                            routes.push(item)
                        }else if(item.type==3){//按钮
                            btns.push(item)
                        }
                    })

                    commit(AUTH_MUTATIONS.SET_BTNS, btns);
                    commit(AUTH_MUTATIONS.SET_ROUTES, routes);
                    commit(AUTH_MUTATIONS.SET_LOADING, false);
                    commit(AUTH_MUTATIONS.SET_IS_REQUEST, true);
                    resolve(ret.data);
                }).catch(e=>{
                    reject(e)
                })
        })
    }
}


export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}