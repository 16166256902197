<template>
  <viewer :images="images" v-bind="$attrs">
    <template #default="scope">
      <img v-for="src in scope.images" :key="src" :src="src" class="image" :style="{ height: height, width: width }" />
    </template>
  </viewer>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    images: {
      type: Array,
      required: true,
    },
    height: {
      type: String,
      default: "30px",
    },
    width: {
      type: String,
      default: "30px",
    },
  },
  setup() {
    const show = () => {};

    return {
      show,
    };
  },
});
</script>

<style>
.image {
  height: 30px;
  width: 30px;
  cursor: pointer;
  margin: 5px;
  display: inline-block;
}
</style>
