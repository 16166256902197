import { createVNode } from '@vue/runtime-dom';
import { Modal, ModalFuncProps } from 'ant-design-vue';
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import Cookies from 'js-cookie'
import config from '@/config/app';
import dayjs, { Dayjs } from 'dayjs';
import isBetween   from 'dayjs/plugin/isBetween'

dayjs.extend(isBetween)

//获取本地存储信息
const getStorage = function (key: string): any {
    return Cookies.get(key);
}

//设置本地存储
const setStorage = function (key: string, value: any, options = {}) {
    Cookies.set(key, value, options)
}

//获取
const getValue = function getValue(obj: any, key: any, default_value: any = null) {
    return Object.prototype.hasOwnProperty.call(obj, key) ? obj[key] : default_value;
}

//弹出确认框
const showConfirm = (props: ModalFuncProps) => {
    props.icon = createVNode(ExclamationCircleOutlined)
    props.okText = "确定"
    props.cancelText = "取消"
    Modal.confirm(props);
}

// 使用es6的padStart()方法来补0
const getYMDHMS = (timestamp: number) => {
    const time = new Date(timestamp)
    const year = time.getFullYear()
    const month = (time.getMonth() + 1).toString().padStart(2, '0')
    const date = (time.getDate()).toString().padStart(2, '0')
    const hours = (time.getHours()).toString().padStart(2, '0')
    const minute = (time.getMinutes()).toString().padStart(2, '0')
    const second = (time.getSeconds()).toString().padStart(2, '0')

    return year + '-' + month + '-' + date + ' ' + hours + ':' + minute + ':' + second
}

//获取图片路径
const getFileUrl = (file: string) => {
    if (!file) {
        return ""
    } else {
        return config.file_base_url + file
    }
}


//判断日期是否在范围内
const dateIsBetween=(minDate:string,maxDate:string,date:Dayjs)=>{
    return date.isBetween(dayjs(minDate).subtract(1,"day"), dayjs(maxDate).add(1,"day"),"day");
}

//为树形结构增加树形
const treeDataFormat=(tree:any,callback:any)=>{
   tree=tree.map((item:any)=>{
       item=callback(item);
       if(item.children&&item.children.length>0){
           item.children=treeDataFormat(item.children,callback)
       }
       return item
   })

   return tree;
}

//获取分页的offset
const getPageOffset=(page:number, pageSize:number)=>{
    const offset = (page - 1) * pageSize
	return offset
}

export {
    getStorage,
    setStorage,
    getValue,
    showConfirm,
    getYMDHMS,
    getFileUrl,
    dateIsBetween,
    treeDataFormat,
    getPageOffset
}