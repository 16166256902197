<template>
  <button :class="classArray" v-bind="$attrs">
    <slot></slot>
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "l-text-button",
  props: {
    type: {
      type: String,
      default: "primary",
    },
  },
  setup(props) {
    const classArray = ["l-text-button", props.type];

    return {
      classArray,
    };
  },
});
</script>

<style lang="scss" scoped>
.l-text-button {
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: none;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
  padding: 0;
  font-size: 14px;

  height: auto;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.85);
  background: transparent;
  border-color: #d9d9d9;

  &:not(:last-child) {
    padding-right: 15px;
  }

  &.primary {
    color: $primary-color;
  }

  &.danger {
    color: $danger-color;
  }
}
</style>
