<template>
  <span>
    <template v-if="hasAuth">
      <slot></slot>
    </template>
    <template v-if="!hasAuth">
      <slot name="denied"></slot>
    </template>
  </span>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "l-auth",
  props: {
    auth: {
      type: String,
      required: true,
    },
  },
  computed: {
    hasAuth() {
      return this.$store.getters["auth/checkBtn"](this.$props.auth);
    },
  },
});
</script>
