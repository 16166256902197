<template>
  <a-col flex="auto">
    <a-form-item style="text-align: right">
      <a-button @click="onReset" style="margin-right: 10px">重置</a-button>
      <a-button type="primary" @click="onSearch">搜索</a-button>

      <a href="javascript:;" style="margin-left: 10px" @click="handleToggle" v-show="slotsCount > 3">
        <span v-show="!visible">展开</span>

        <span v-show="visible">收起</span>

        <DownOutlined class="l-search-toggle-icon" :class="{ 'l-visible': visible, 'l-hide': !visible }" />
      </a>
    </a-form-item>
  </a-col>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "l-search-container-action",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    slotsCount: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    //点击搜索按钮
    const onSearch = () => {
      emit("search");
    };

    //点击重置按钮
    const onReset = () => {
      emit("reset");
    };

    //点击展开/关闭
    const handleToggle = () => {
      emit("update:visible", !props.visible);
    };

    return {
      onSearch,
      onReset,
      handleToggle,
    };
  },
});
</script>

<style lang="scss">
.l-search-toggle-icon {
  transition: all 0.3s;
}

.l-visible {
  transform: rotate(180deg);
}

.l-hide {
  transform: rotate(0deg);
}
</style>
