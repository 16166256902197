<template>
  <div>
    <l-form :rules="rules" :model="formState" ref="formRef">
      <a-row :gutter="20">
        <a-col :span="24">
          <a-form-item label="密码" name="password">
            <a-input
              type="password"
              v-model:value="formState.password"
              placeholder="请输入密码"
            />
          </a-form-item>
        </a-col>

        <a-col :span="24">
          <a-form-item label="密码确认" name="re_password">
            <a-input
              type="password"
              v-model:value="formState.re_password"
              placeholder="请再次输入密码"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <div class="l-drawer-edit-footer">
        <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
        <a-button type="primary" @click="handleSubmit" :loading="submitLoading"
          >提交</a-button
        >
      </div>
    </l-form>
  </div>
</template>

<script lang="ts">
import http from "@/common/http";
import { message } from "ant-design-vue";
import { defineComponent, reactive, ref, UnwrapRef } from "vue";

interface FormState {
  id: number | undefined;
  password: string;
  re_password: string;
}

export default defineComponent({
  name: "UserChangePwd",
  props: {
    id: {
      type: Number,
    },
  },
  setup(props, { emit }) {
    const formRef = ref();

    const submitLoading = ref(false);

    let formState: UnwrapRef<FormState> = reactive({
      id: props.id,
      password: "",
      re_password: "",
    });

    //验证规则
    const rules = {
      password: [{ required: true, message: "请输入密码", trigger: "change" }],
      re_password: [{ required: true, message: "请再次输入密码", trigger: "change" }],
    };

    //点击取消
    const onClose = () => {
      emit("update:id", 0);
      emit("close");
    };

    //提交
    const handleSubmit = () => {
      formRef.value.validate().then(async () => {
        submitLoading.value = true;
        try {
          if (!props.id) {
            //新增
            await http.put("user/changepwd", formState);
            message.success("修改成功");
          } else {
            //修改
            await http.put(`user/${props.id}/pwd`, formState);
            message.success("修改成功");
          }
        } finally {
          submitLoading.value = false;
        }

        onClose();
        emit("success");
      });
    };

    return {
      formRef,
      formState,
      rules,
      submitLoading,
      onClose,
      handleSubmit,
    };
  },
});
</script>
