<template>
  <a-modal centered :maskClosable="false" okText="确定" cancelText="取消" destroyOnClose v-bind="$props">
    <template v-for="(item, key, index) in $slots" :key="index" v-slot:[key]>
      <slot :name="key"></slot>
    </template>
  </a-modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "l-modal",
  setup() {},
});
</script>

<style lang="scss">
.l-max-modal-height {
  max-height: 80vh;
  overflow: auto;
}
</style>
