<template>
  <div>
    <a-row style="margin-bottom: 10px">
      <a-col :span="12" @change.stop>
        <slot name="toolbar-left"></slot>
      </a-col>
      <a-col :span="12">
        <div class="l-toolbar-right-content" style="text-align: right">
          <slot name="toolbar-action"></slot>
          <div class="action-icon" v-show="showActionIcon">
            <a-tooltip>
              <template v-slot:title> 刷新 </template>
              <ReloadOutlined class="l-toolbar-action-icon" @click="reload" />
            </a-tooltip>

            <a-dropdown trigger="click">
              <a-tooltip>
                <template v-slot:title> 尺寸设置 </template>
                <ColumnHeightOutlined class="l-toolbar-action-icon" />
              </a-tooltip>

              <template v-slot:overlay>
                <a-menu style="width: 80px" v-model:selectedKeys="tableSize" @click="handleSizeSelect">
                  <a-menu-item :key="item.key" v-for="item in tableSizeList">
                    {{ item.name }}
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>

            <a-popover title="" trigger="click" placement="bottomRight">
              <template v-slot:content>
                <a-row type="flex" justify="space-between">
                  <a-col>
                    <a-checkbox v-model:checked="tableColumnsState.checkAll" :indeterminate="tableColumnsState.indeterminate" @change="handleTableColumsChange"> 列选择 </a-checkbox>
                  </a-col>
                  <a-col>
                    <a href="javascript:;" @click="resetTableColums">重置</a>
                  </a-col>
                </a-row>

                <a-divider />

                <a-checkbox-group v-model:value="tableColumnsState.checkedList">
                  <a-row type="flex" :gutter="[10, 5]" style="max-width: 150px">
                    <a-col :span="24" v-for="item in selectColumnsList" :key="item.value">
                      <a-checkbox :value="item.value">{{ item.label }}</a-checkbox>
                    </a-col>
                  </a-row>
                </a-checkbox-group>
              </template>

              <a-tooltip>
                <template v-slot:title> 列设置 </template>
                <span style="padding-right: 5px">
                  <SettingOutlined class="l-toolbar-action-icon" />
                </span>
              </a-tooltip>
            </a-popover>
          </div>
        </div>
      </a-col>
    </a-row>
    <a-table :columns="tableColumns" table-layout="fixed" sticky :scroll="{ x: computedScrollXValue, y: height }" :size="tableSize[0]" ref="table" @resizeColumn="handleResizeColumn" v-bind="$attrs">
      <template v-for="(item, key, index) in $slots" :key="index" v-slot:[key]="slot">
        <slot :name="key" v-bind="slot" v-if="key != 'toolbar-action'"></slot>
      </template>
    </a-table>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, inject, reactive, Ref, ref, watch } from "vue";

export default defineComponent({
  name: "lTable",
  props: {
    columns: {
      type: Array,
      required: true,
    },
    offsetHeight: {
      type: Number,
      default: 370,
    },

    scrollXValue: {
      type: [Number, String, Boolean],
      default: 2000,
    },

    defaultTableSize: {
      type: String,
      default: "",
    },
    showActionIcon: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const fontRootValue = inject<Ref<number>>("fontRootValue");
    const height = window.innerHeight - props.offsetHeight;
    const table: any = ref(null);
    const tableSize = ref(["middle"]);
    if (props.defaultTableSize != "") {
      tableSize.value = [props.defaultTableSize];
    }

    //表格宽度
    const computedScrollXValue = computed(() => {
      if (props.scrollXValue !== 2000) {
        return props.scrollXValue;
      } else {
        let total = 0;
        props.columns.forEach((item: any) => {
          const rootValue = fontRootValue!.value || 1;
          const width = Math.ceil(rootValue * item.width);

          if (!item.width) {
            total += 100;
          } else {
            total += width;
          }
        });

        console.log(total);

        return total;
      }
    });

    //列
    const computedColums = computed(() => {
      let colums: any = [];
      props.columns.forEach((item: any) => {
        let rootValue = fontRootValue!.value || 1;
        colums.push({
          ...item,
          width: Math.ceil(rootValue * item.width),
        });
      });

      return colums;
    });

    //控制表格尺寸
    const tableSizeList = [
      {
        key: "default",
        name: "稀疏",
      },
      {
        key: "middle",
        name: "中等",
      },
      {
        key: "small",
        name: "紧凑",
      },
    ];
    //点击表格尺寸选择
    const handleSizeSelect = ({ key }: { key: string }) => {
      tableSize.value = [key];
    };

    //点击刷新按钮
    const reload = () => {
      emit("reload");
    };

    //控制列显示
    const selectColumnsList: any[] = [];
    const checkedList: any[] = [];
    props.columns.forEach((item: any) => {
      selectColumnsList.push({
        value: item.dataIndex,
        label: item.title,
      });
      checkedList.push(item.dataIndex);
    });

    const tableColumnsState = reactive({
      checkAll: true,
      indeterminate: false,
      checkedList: checkedList,
    });

    //展示的列
    const tableColumns = computed(() => {
      return computedColums.value.filter((item: any) => {
        const index = tableColumnsState.checkedList.findIndex(itm => {
          return itm == item.dataIndex;
        });

        return index != -1;
      });
    });

    //列全选
    const handleTableColumsChange = (e: any) => {
      Object.assign(tableColumnsState, {
        checkedList: e.target.checked ? checkedList : [],
        indeterminate: false,
      });
    };

    //重置列选择
    const resetTableColums = () => {
      tableColumnsState.checkedList = checkedList;
    };

    watch(
      () => tableColumnsState.checkedList,
      val => {
        tableColumnsState.indeterminate = !!val.length && val.length < props.columns.length;
        tableColumnsState.checkAll = val.length === props.columns.length;
      }
    );

    watch(tableColumns, val => {
      console.log(val);
    });

    const handleResizeColumn = (w: any, col: any) => {
      col.width = w;
    };

    return {
      height,
      table,
      tableColumns,
      selectColumnsList,
      tableSize,
      tableSizeList,
      tableColumnsState,
      computedScrollXValue,
      computedColums,
      handleSizeSelect,
      reload,
      handleTableColumsChange,
      resetTableColums,
      handleResizeColumn,
    };
  },
});
</script>

<style lang="scss" scoped>
.l-toolbar-right-content {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .action-icon {
    margin-left: 20px;
    white-space: nowrap;
  }

  .l-toolbar-action-icon {
    cursor: pointer;
    font-size: 17px;
    &:not(:last-child) {
      margin-right: 15px;
    }
  }
}
</style>
