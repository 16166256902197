
import { PaginationProps } from "ant-design-vue"

//分页配置
export const pagConfig:PaginationProps = {
    current:1,
    pageSize: 30,
    showSizeChanger: true,
    showQuickJumper: true,
    showTotal: (total: number) => `共 ${total} 条`,
}

//搜索框的响应式数值
export const searchCol = {
    xl: {
        span: 6
    },
    lg: {
        span: 8
    },
    md:{
        span: 12
    },
    sm:{
        span: 24
    },
    xs:{
        span: 24
    }
}

const config = {
    api: {
        //请求地址
        // base_url: 'http://localhost:8088/v1/',
        base_url: 'http://127.0.0.1:9502/v1/',

    },
    //显示图片/文件的路径
    file_base_url: "http://192.168.2.178:9502/",

    //socketio地址
    socketio_url:"192.168.2.178:9502"
}

//正式环境
if (process.env.NODE_ENV == 'production') {
    const build_env = process.env.VUE_APP_BUILD
    switch (build_env) {
        case "test":
            config.api.base_url = ""
            config.file_base_url = ""
            config.socketio_url = ""
            break;
        case "pro":
            config.api.base_url = "https://szapi.liyuegame.com/v1/"
            config.file_base_url = "https://szapi.liyuegame.com/"
            config.socketio_url = "wss://szapi.liyuegame.com"
            break;
    }

}

export default config