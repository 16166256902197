
import { defineComponent, useSlots } from "vue";

export default defineComponent({
  name: "lTableContainer",
  setup() {
    const showSearch = !!useSlots().search;

    return {
      showSearch,
    };
  },
});
