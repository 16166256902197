
import http from "@/common/http";
import { message } from "ant-design-vue";
import { defineComponent, reactive, ref, UnwrapRef } from "vue";

interface FormState {
  id: number | undefined;
  password: string;
  re_password: string;
}

export default defineComponent({
  name: "UserChangePwd",
  props: {
    id: {
      type: Number,
    },
  },
  setup(props, { emit }) {
    const formRef = ref();

    const submitLoading = ref(false);

    let formState: UnwrapRef<FormState> = reactive({
      id: props.id,
      password: "",
      re_password: "",
    });

    //验证规则
    const rules = {
      password: [{ required: true, message: "请输入密码", trigger: "change" }],
      re_password: [{ required: true, message: "请再次输入密码", trigger: "change" }],
    };

    //点击取消
    const onClose = () => {
      emit("update:id", 0);
      emit("close");
    };

    //提交
    const handleSubmit = () => {
      formRef.value.validate().then(async () => {
        submitLoading.value = true;
        try {
          if (!props.id) {
            //新增
            await http.put("user/changepwd", formState);
            message.success("修改成功");
          } else {
            //修改
            await http.put(`user/${props.id}/pwd`, formState);
            message.success("修改成功");
          }
        } finally {
          submitLoading.value = false;
        }

        onClose();
        emit("success");
      });
    };

    return {
      formRef,
      formState,
      rules,
      submitLoading,
      onClose,
      handleSubmit,
    };
  },
});
